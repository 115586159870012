import { apiEndpointUrl, xApiKey } from 'env'
import { GenericError, handleNetworkResponse } from 'lib/error'

export function createConsent(questionnaireId: string, authToken: string) {
  return fetch(
    `${apiEndpointUrl}/v1/questionnaire/${questionnaireId}/consent`,
    {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authToken}`,
        'x-api-key': xApiKey
      },
      body: '{}'
    }
  )
    .then(handleNetworkResponse)
    .then(res => res.json())
    .then(body => {
      if (!body.consentId) throw new Error()

      return body.consentId
    })
    .catch(() => {
      throw new GenericError(
        'An unexpected error has occurred and we could not create a record of consent for this questionnaire.'
      )
    })
}
