import { RadioButton, RadioGroup } from '@te-whatu-ora/anatomic'
import type { RadioButtonGroupProps } from '@healthnz-ult/fhir-engine-renderer/types'
import { useAdditionalLabelProperties } from 'components/fhirEngine/utils/additionalProperties'
import { RequiredInputWrapper } from 'components/fhirEngine/utils/requiredLabel'
import { InputFieldset } from '../inputFieldset'

export default function RadioButtonGroup(props: RadioButtonGroupProps) {
  const {
    helperText,
    inputId,
    onChange,
    options,
    error,
    value,
    labelComponent,
    required
  } = props

  function handleChange(newValue: string) {
    if (!newValue.startsWith('index-')) return

    const parsedValue = Number.parseInt(newValue.slice(6), 10)

    onChange(parsedValue)
  }

  const additionalProps = useAdditionalLabelProperties(props)

  return (
    <RequiredInputWrapper
      inputId={inputId}
      labelComponent={labelComponent}
      required={required}
    >
      <InputFieldset {...additionalProps}>
        <RadioGroup
          required={false}
          label={undefined as unknown as string}
          errorMessage={error}
          helperText={helperText}
          name={inputId}
          onChange={handleChange}
          value={`index-${value}` ?? undefined}
        >
          {options.map(option => {
            return (
              <RadioButton
                id={`${inputId}-${option.value}`}
                key={option.value}
                label={option.label}
                value={`index-${option.value}`}
              />
            )
          })}
        </RadioGroup>
      </InputFieldset>
    </RequiredInputWrapper>
  )
}
