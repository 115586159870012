import { Box, Icon, InputLabel, Text } from '@te-whatu-ora/anatomic'

export interface InputLegendProps
  extends Omit<React.ComponentProps<typeof InputLabel>, 'label'> {
  label?: React.ReactNode
  labelComponent?: React.ReactNode
}

export const InputLegend = ({
  disabled,
  error = false,
  label,
  labelComponent,
  required,
  subheading,
  href,
  tertiaryLabelAs,
  tertiaryLabelIcon,
  tertiaryLabelIconPosition
}: InputLegendProps) => {
  const labelColor = error && !disabled ? 'error100' : 'positive0'

  const displayTertiary =
    !!href &&
    !!tertiaryLabelIcon &&
    !!tertiaryLabelAs &&
    !!tertiaryLabelIconPosition

  return (
    <Box
      as='legend'
      display='flex'
      flexDirection='row'
      justifyContent='spaceBetween'
      width='full'
    >
      <Text color={labelColor} weight='bold'>
        {labelComponent ?? label}{' '}
        {!!required && !!label && !labelComponent && '*'}
        <Text color={labelColor} size='small'>
          {subheading}
        </Text>
      </Text>
      {displayTertiary && (
        <a href={href}>
          <Icon
            aria-label={`Link to ${href}`}
            className='support-link-icon'
            icon={tertiaryLabelIcon}
            variant='functionalIcons'
          />
        </a>
      )}
    </Box>
  )
}
