import { Box, InputMessage, ToggleSwitch } from '@te-whatu-ora/anatomic'
import React, { Dispatch, SetStateAction } from 'react'

import {
  Bundle,
  CommonInputProps,
  InputReferenceList,
  InputReferenceSearch,
  isResourceType,
  ReferenceCard,
  ResourceType,
  User
} from 'components/fhirEngine/referenceInput'
import { InputLabel } from 'components/fhirEngine/inputLabel/InputLabel'
import { Loader } from 'components/loader'
import { Show } from 'components/show'

import 'components/fhirEngine/referenceInput/styles.css'

export interface InputReferenceProps extends CommonInputProps {
  handleSearch: (inputValue: string) => Promise<Bundle | User | undefined>
  queryLoading?: boolean
  searchById: boolean
  setPrivateError: Dispatch<SetStateAction<string | undefined>>
  setSearchById: Dispatch<SetStateAction<boolean>>
}

export default function InputReference(props: InputReferenceProps) {
  const {
    error,
    handleRemove,
    label,
    queryLoading,
    referenceResource,
    searchById,
    setSearchById,
    value
  } = props

  const SearchComponent = searchById ? InputReferenceSearch : InputReferenceList

  const showLoading = queryLoading && searchById

  return (
    <>
      <InputLabel {...props} labelComponent={label} label='' />

      {isResourceType(referenceResource, ResourceType.Practitioner) && (
        <Box className='reference-input__toggle-container'>
          <ToggleSwitch
            defaultChecked
            id='hpiSwitch'
            label='Search by HPI or name'
            onChange={() => setSearchById(!searchById)}
          />
        </Box>
      )}

      <SearchComponent {...props} />

      <InputMessage errorMessage={error} />

      <Show when={!showLoading} fallback={<Loader message='Loading' />}>
        <Show when={value}>
          {value?.map((val, ind) => (
            <ReferenceCard
              displayValue={val}
              key={ind}
              onRemove={handleRemove}
            />
          ))}
        </Show>
      </Show>
    </>
  )
}
