import { MouseEvent, MutableRefObject, RefCallback } from 'react'

type RefType<T> = MutableRefObject<T> | RefCallback<T> | null

export function shareRef<T>(...refs: RefType<T | null>[]): RefCallback<T> {
  return function Callback(instance) {
    refs.forEach(ref => {
      if (typeof ref === 'function') {
        ref(instance)
      } else if (ref) {
        // eslint-disable-next-line no-param-reassign
        ref.current = instance
      }
    })
  }
}

// find closest element to a mouse event
export function findClosestElement<E extends Element>(
  e: MouseEvent<HTMLDivElement>,
  ...elements: E[]
): E {
  const clickX = e.clientX
  const clickY = e.clientY

  return (
    elements
      // calc distance for each element
      .map(element => {
        const { left, right, top, bottom } = element.getBoundingClientRect()

        const dx = Math.max(left - clickX, 0, clickX - right)
        const dy = Math.max(top - clickY, 0, clickY - bottom)

        return {
          element,
          distance: Math.sqrt(dx * dx + dy * dy)
        }
      })
      // sort ascending and return first (closest) element
      .sort((a, b) => a.distance - b.distance)[0]?.element
  )
}

// focuses the nearest input to a click event.
// will set the caret to be at the closest end of the input before focusing
export function focusNearestInput(
  e: MouseEvent<HTMLDivElement>,
  ...inputs: HTMLInputElement[]
) {
  // if we've clicked one of the inputs directly, use the default behaviour
  if (inputs.includes(e.target as HTMLInputElement)) return

  const closestElement = findClosestElement(e, ...inputs)

  // get distance of click from left and right sides of closest element
  const { left, right } = closestElement.getBoundingClientRect()
  const dLeft = Math.abs(e.clientX - left)
  const dRight = Math.abs(e.clientX - right)

  // if closer to left, set caret at start, else set at end
  const caretPos = dLeft < dRight ? 0 : closestElement.value.length
  closestElement.setSelectionRange(caretPos, caretPos)
  closestElement.focus()
}
