import { DayOfWeekShape, DayPickerSingleDateController } from 'react-dates'
import moment, { type Moment } from 'moment'
import { Icon } from '@te-whatu-ora/anatomic'
import { forwardRef, Ref } from 'react'
import { InputDateValue } from '../dateInput/utils'

interface NavButtonProps {
  next: boolean
}

function NavButton({ next }: NavButtonProps): JSX.Element {
  const className = next ? 'calendar__next' : 'calendar__prev'
  const icon = next ? 'chevron_right' : 'chevron_left'
  return <Icon icon={icon} variant='functionalIcons' className={className} />
}

function momentFromDateValue({
  day,
  month,
  year
}: InputDateValue): Moment | null {
  if (!day || !month || !year) return null

  const date = moment({
    day: Number.parseInt(day, 10),
    month: Number.parseInt(month, 10) - 1,
    year: Number.parseInt(year, 10)
  })

  if (!date.isValid()) return null

  return date
}

export interface DatePickerProps {
  onChange: (newValue: InputDateValue) => void
  value: InputDateValue
}

export const DatePicker = forwardRef(
  ({ onChange, value }: DatePickerProps, ref: Ref<any>) => {
    function handleChange(newDate: Moment | null) {
      if (newDate === null) return onChange({})

      onChange({
        day: newDate.date().toString().padStart(2, '0'),
        month: (newDate.month() + 1).toString().padStart(2, '0'),
        year: newDate.year().toString().padStart(4, '0')
      })
    }

    return (
      <div ref={ref}>
        <DayPickerSingleDateController
          date={momentFromDateValue(value)}
          focused
          initialVisibleMonth={() => momentFromDateValue(value) ?? moment()}
          onDateChange={handleChange}
          onFocusChange={() => {}}
          // calendar presentation and interaction related props
          firstDayOfWeek={1 as DayOfWeekShape} // Monday
          hideKeyboardShortcutsPanel
          noBorder
          numberOfMonths={1}
          // navigation related props
          navNext={<NavButton next />}
          navPrev={<NavButton next={false} />}
          // internationalization
          monthFormat='MMMM YYYY'
          weekDayFormat='ddd'
        />
      </div>
    )
  }
)
