import {
  Box,
  Card,
  Column,
  Heading,
  Icon,
  Row,
  TextLinkButton
} from '@te-whatu-ora/anatomic'
import { Show } from 'components/show'
import type { PageLayoutProps } from '@healthnz-ult/fhir-engine-renderer/types'
import React, { Children } from 'react'
import { ModalManager } from 'components/modalManager/ModalManager'
import { ContentLoader, NextSubmitButtons } from 'components/shared'
import { useSubmitState } from 'contexts/submitState/submitState'

export default function PageLayout({
  children,
  isPartialSaveLoading,
  questionnaireTitle,
  questionnaireTitleComponent,
  onPrev,
  onNext,
  onSubmit,
  submitButtonLabel,
  title,
  validate
}: PageLayoutProps) {
  const { submitState } = useSubmitState()

  return (
    <main>
      <Show when={questionnaireTitleComponent || questionnaireTitle}>
        <Box paddingBottom='small'>
          <Heading level='2' color='primary100' as='h1'>
            {questionnaireTitleComponent ?? questionnaireTitle}
          </Heading>
        </Box>
      </Show>
      <ModalManager />
      <Row>
        <Column columns={12}>
          <Show when={onPrev && ['ready', 'invalid'].includes(submitState)}>
            <Box marginBottom='xsmall'>
              <TextLinkButton onPress={onPrev}>
                <Box display='flex' alignItems='center'>
                  <Icon
                    icon='arrow_left'
                    marginRight='xsmall'
                    variant='functionalIcons'
                  />
                  Previous step
                </Box>
              </TextLinkButton>
            </Box>
          </Show>
          <Show when={title}>
            <Box marginBottom='xsmall'>
              <Heading level='3' color='primary100' as='h1'>
                {title}
              </Heading>
            </Box>
          </Show>
          <ContentLoader state={submitState} loadingMessage='Submitting'>
            {Children.map(children, (item, itemIndex) => (
              <Card key={itemIndex}>
                {item}
                <Show when={itemIndex === Children.count(children) - 1}>
                  <NextSubmitButtons
                    hasSubmit={!!onSubmit}
                    isPartialSaveLoading={isPartialSaveLoading}
                    onNext={onNext}
                    submitButtonLabel={submitButtonLabel}
                    submitState={submitState}
                    validate={validate}
                  />
                </Show>
              </Card>
            ))}
          </ContentLoader>
        </Column>
      </Row>
    </main>
  )
}
