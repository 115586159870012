import * as process from 'process'

export const apiEndpointUrl = process.env.REACT_APP_API_ENDPOINT_URL!
export const xApiKey = process.env.REACT_APP_X_API_KEY!
export const appEnvironment = process.env.REACT_APP_ENV!
export const appEndpointUrl = process.env.REACT_APP_ENDPOINT_URL!

if (!apiEndpointUrl) throw new Error('apiEndpointUrl not found in env')
if (!xApiKey) throw new Error('xApiKey not found in env')
if (!appEnvironment) throw new Error('appEnvironment not found in env')
if (!appEndpointUrl) throw new Error('appEndpointUrl not found in env')
