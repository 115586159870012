// eslint-disabled import/no-extraneous-dependencies
import { Navigate, Route } from 'react-router-dom'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import QuestionnairePage from 'pages/questionnaire/questionnaire'
import SubmittedPage from 'pages/submitted/submitted'
import { Router } from 'components/router'
import EmbedLayout from 'components/embedLayout'
import RootLayout from 'components/rootLayout'
import FourOhFourPage from 'pages/404/404'
import MaintenancePage from 'pages/maintenance/maintenance'
import { withErrorBoundary } from 'components/errorBoundary'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import EmbeddedQuestionnairePage from 'pages/embeddedQuestionnaire'

// make web components available inside questionnaire
import '../webComponents'

export default function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Router>
        <Route path='*' element={<Navigate to='/404' replace />} />
        <Route path='embed' Component={EmbedLayout}>
          <Route
            path=':questionnaireId/*'
            Component={withErrorBoundary(EmbeddedQuestionnairePage, true)}
          />
          <Route path='maintenance' element={<MaintenancePage />} />
        </Route>
        <Route Component={RootLayout}>
          <Route path='/' element={<Navigate to='/docs' replace />} />
          <Route
            path='/:questionnaireId/*'
            Component={withErrorBoundary(QuestionnairePage, false)}
          />
          <Route path='/submitted' Component={SubmittedPage} />
          <Route path='/maintenance' element={<MaintenancePage />} />
          <Route path='/404' element={<FourOhFourPage />} />
        </Route>
      </Router>
    </LocalizationProvider>
  )
}
