import type {
  Reference,
  ReferenceInputProps
} from '@healthnz-ult/fhir-engine-renderer/types'

import { CommonInput } from 'components/fhirEngine/referenceInput'

export default function ReferenceInput(props: ReferenceInputProps) {
  const {
    error,
    inputId,
    label,
    labelComponent,
    onBlur,
    onChange,
    onFocus,
    referenceResource,
    required,
    value
  } = props

  function handleAdd(newReference: Reference) {
    onChange(newReference)
  }

  function handleRemove() {
    onChange(null)
  }

  // We only display the required '*' if the label doesn't have a component, or it'll mess with the layout.
  // Include the '*' in the labelComponent.
  return (
    <CommonInput
      error={error}
      handleRemove={handleRemove}
      inputId={inputId}
      label={labelComponent ?? label}
      onAdd={handleAdd}
      onBlur={onBlur}
      onFocus={onFocus}
      referenceResource={referenceResource}
      required={!labelComponent && required}
      value={value ? [value] : null}
    />
  )
}
