import { InputDate } from '@te-whatu-ora/anatomic'

export type InputDateValue = NonNullable<
  React.ComponentProps<typeof InputDate>['value']
>

export function toInputDateValue(date: string | null): InputDateValue {
  if (date === null) return {}

  const [year, month, day] = date.split('-')

  return {
    day,
    month,
    year
  }
}

// Date (YYYY-MM-DD) or Partial Date (YYYY-MM or YYYY)
export function toDateString({
  day,
  month,
  year
}: InputDateValue): string | null {
  if (!day && !month && !year) return null

  // For partial dates, we can choose the precision. We remove trailing hyphens as these
  // indicate less precision not missing parts of the date.
  // ie. YYYY-- is complete year precision while YYYY--DD is day precision with a missing month
  return `${year ?? ''}-${month ?? ''}-${day ?? ''}`.replaceAll(/-+$/g, '')
}

// YYYY-MM-DD
export function toFullDateString({
  day,
  month,
  year
}: InputDateValue): string | null {
  return `${year ?? ''}-${month ?? ''}-${day ?? ''}`
}
