export interface PartialSeparatorProps {
  separator: string
}

export function PartialSeparator({ separator }: PartialSeparatorProps) {
  return (
    <span className='partialSeparator' aria-hidden>
      {separator}
    </span>
  )
}
