import { ModalProps } from '@healthnz-ult/fhir-engine-renderer/types'
import { Box, Button, Stack } from '@te-whatu-ora/anatomic'
import { BaseModal } from '../baseModal/BaseModal'

export function Modal({ children, onClose, title }: ModalProps) {
  return (
    <BaseModal onClose={onClose} title={title}>
      <div>
        <Stack space='small'>{children}</Stack>
        <Box
          marginTop='medium'
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Button onPress={onClose}>OK</Button>
        </Box>
      </div>
    </BaseModal>
  )
}
