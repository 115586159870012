/* eslint-disable react/no-unused-prop-types */
import { Box, Button } from '@te-whatu-ora/anatomic'
import { Show } from 'components/show'
import React, { useEffect, useRef } from 'react'
import { Loader } from 'components/loader'

import '../../webComponents/OpenModal'

export interface NextSubmitButtonsProps {
  hasSubmit: boolean
  isPartialSaveLoading: boolean
  onNext?: () => void
  submitButtonLabel: string
  submitState: 'error' | 'success' | 'loading' | 'ready'
  validate: () => string | undefined
}

export function NextSubmitButtons({
  hasSubmit,
  isPartialSaveLoading,
  onNext,
  submitButtonLabel,
  submitState,
  validate
}: NextSubmitButtonsProps) {
  const openModalRef = useRef<HTMLElement>(null)

  useEffect(() => {
    // Set attributes directly on the web component using the ref
    if (openModalRef.current) {
      openModalRef.current.style.visibility = 'hidden'
    }
  }, [])

  function triggerModal() {
    const validation = validate()
    if (validation) return
    if (openModalRef.current) {
      openModalRef.current.click()
    }
  }

  return (
    <Box paddingTop='medium'>
      <Show when={onNext}>
        <Show
          when={!isPartialSaveLoading}
          fallback={<Loader message='Loading' />}
        >
          <Box
            style={{ display: 'flex', justifyContent: 'center' }}
            paddingTop='small'
          >
            <Button variant='primary' onPress={onNext}>
              Continue
            </Button>
          </Box>
        </Show>
      </Show>

      <open-modal ref={openModalRef} linkId='submit-modal' />

      <Show when={hasSubmit}>
        <Show
          when={submitState !== 'success'}
          fallback={<Button variant='primary'>Success</Button>}
        >
          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              disabled={submitState === 'loading'}
              onClick={triggerModal}
              variant='primary'
            >
              {submitButtonLabel}
            </Button>
          </Box>
        </Show>
      </Show>
    </Box>
  )
}
