import { Box, InputMessage } from '@te-whatu-ora/anatomic'
import { MultiValue } from 'react-select'
import Creatable from 'react-select/creatable'
import {
  CreatableMultiSelectProps,
  InputOption
} from '@healthnz-ult/fhir-engine-renderer/types'
import { InputLabel } from 'components/fhirEngine/inputLabel/InputLabel'
import React from 'react'
import { CreatableOption } from './type'
import {
  ClearIndicator,
  Control,
  DropdownIndicator,
  MultiValue as MultiValueComponent
} from './components'

const valueToOptionFor =
  (options: InputOption[]) =>
  (value: string | number): CreatableOption => {
    if (typeof value === 'number') return options[value]

    return { value, label: value }
  }

export default function CreatableMultiSelect(props: CreatableMultiSelectProps) {
  const {
    error,
    helperText,
    inputId,
    onBlur,
    onChange,
    onFocus,
    options,
    value
  } = props

  const valueAsOptions = value?.map(valueToOptionFor(options)) ?? []

  function handleChange(newValue: MultiValue<CreatableOption>) {
    onChange(newValue.map(v => v.value))
  }

  return (
    <Box>
      <InputLabel {...props} />
      <Creatable
        components={{
          ClearIndicator,
          Control,
          DropdownIndicator,
          MultiValue: MultiValueComponent
        }}
        inputId={inputId}
        isClearable
        isMulti
        onBlur={onBlur}
        onChange={handleChange}
        onFocus={onFocus}
        options={options}
        required
        value={valueAsOptions}
      />
      <InputMessage errorMessage={error} helperText={helperText} />
    </Box>
  )
}
