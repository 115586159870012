import { QuestionLoadingProps } from '@healthnz-ult/fhir-engine-renderer/types'
import { Box, InputLabel, InputMessage, Loader } from '@te-whatu-ora/anatomic'

export function QuestionLoading({
  label,
  required,
  helperText
}: QuestionLoadingProps) {
  return (
    <>
      <InputLabel label={label ?? ''} required={required} />
      <Box marginY='small' style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <Loader message='loading question' />
      </Box>
      <InputMessage helperText={helperText} />
    </>
  )
}
