import {
  Checkbox,
  CheckboxGroup as AnatomicCheckboxGroup
} from '@te-whatu-ora/anatomic'
import type { CheckboxGroupProps } from '@healthnz-ult/fhir-engine-renderer/types'
import { useAdditionalLabelProperties } from 'components/fhirEngine/utils/additionalProperties'
import { RequiredInputWrapper } from 'components/fhirEngine/utils/requiredLabel'

export default function CheckboxGroup(props: CheckboxGroupProps) {
  const {
    error,
    helperText,
    inputId,
    labelComponent,
    onAdd,
    onRemove,
    options,
    required,
    value
  } = props

  const handleChange = (optionValue: number) => (checked: boolean) => {
    if (checked) {
      onAdd(optionValue)
    } else {
      onRemove(optionValue)
    }
  }

  const additionalProps = useAdditionalLabelProperties(props)

  return (
    <RequiredInputWrapper
      inputId={inputId}
      labelComponent={labelComponent}
      required={required}
    >
      <AnatomicCheckboxGroup
        {...additionalProps}
        errorMessage={error}
        helperText={helperText}
        required={required}
      >
        {options.map(option => (
          <Checkbox
            checked={value?.includes(option.value) ?? false}
            id={`${inputId}-${option.value}`}
            key={option.value}
            label={option.label}
            onCheckedChange={handleChange(option.value)}
          />
        ))}
      </AnatomicCheckboxGroup>
    </RequiredInputWrapper>
  )
}
