export type InputTimeValue = {
  hour?: string // 1-12
  minute?: string // 0-59
  period?: string // AM | PM
}

export function hour12to24(value: InputTimeValue) {
  const { hour, period } = value

  if (hour === undefined || hour === '' || hour === '0') return hour

  const hourNumber = Number.parseInt(hour, 10)

  if (Number.isNaN(hourNumber)) return hour
  if (period === 'AM' && hourNumber === 12) return '00'
  if (period === 'AM') return hour
  if (period === 'PM' && hourNumber === 12) return '12'
  if (period === 'PM')
    return (hourNumber + 12).toString().padStart(hour.length, '0')
  return hour
}

export function toTimeString(value: InputTimeValue): string | null {
  const { hour, minute, period } = value

  if (!hour && !minute && !period) return null
  return `${hour12to24(value)}:${minute ?? ''}:00`
}

export function hour24to12(value: string) {
  const hour = Number.parseInt(value, 10)

  if (Number.isNaN(value)) return value
  if (value === '00') return '12'
  if (hour === 12) return '12'
  if (hour > 12) return (hour - 12).toString().padStart(2, '0')
  return value
}

export function hour24toPeriod(value: string): string | undefined {
  const hour = Number.parseInt(value, 10)

  if (Number.isNaN(hour)) return undefined
  return hour < 12 ? 'AM' : 'PM'
}

export function toTimeValue(
  value: string | null,
  cachedPeriod?: string | undefined | null
): InputTimeValue {
  if (value === null) return {}

  const [hour, minute] = value.split(':')

  return {
    hour: hour24to12(hour),
    minute,
    period:
      // if we haven't cached a period, then the value isn't coming from user input
      // if chachedPeriod === 'AM' and the calcd period is 'PM' then our cached value is stale
      // this is bc 01 could be AM or PM (prior to the user selecting a period) but 13 is definitely PM
      cachedPeriod === null || cachedPeriod === 'AM'
        ? hour24toPeriod(hour)
        : cachedPeriod
  }
}
