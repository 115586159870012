import { DateTimeInputProps } from '@healthnz-ult/fhir-engine-renderer/types'
import { useState } from 'react'
import { InputDateTime } from './InputDateTime'
import { DateTimeValue, toDateTimeString, toDateTimeValue } from './utils'

// This is a bit janky but avoids reimplementing DateInput and TimeInput
// In future we can replace with an Anatomic DateTime
export default function DateTimeInput({
  error,
  helperText,
  inputId,
  label,
  labelComponent,
  required,
  value,
  onBlur,
  onChange,
  supportLink
}: DateTimeInputProps) {
  const [period, setPeriod] = useState<string | undefined | null>(null)

  function handleChange(newValue: DateTimeValue) {
    setPeriod(newValue.period)
    onChange(toDateTimeString(newValue))
  }

  // We only display the required '*' if the label doesn't have a component, or it'll mess with the layout.
  // Include the '*' in the labelComponent.
  return (
    <InputDateTime
      errorMessage={error}
      helperText={helperText}
      label={label}
      labelComponent={labelComponent}
      name={`${inputId}-date`}
      required={!labelComponent && required}
      value={toDateTimeValue(value, period)}
      onBlur={onBlur}
      onChange={handleChange}
      supportLink={supportLink}
    />
  )
}
