import { InputFieldStyles, useText } from '@te-whatu-ora/anatomic'
import {
  ChangeEvent,
  DetailedHTMLProps,
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  KeyboardEvent,
  useRef
} from 'react'
import { shareRef } from './utils'

export interface PartialInputProps {
  'aria-label'?: string
  'aria-describedby'?: string
  filterValue?: (value: string) => string
  id: string
  inputMode: DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >['inputMode']
  maxLength?: number
  onChange: (value: string) => void
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void
  pattern?: string
  placeholder?: string
  selectNext?: () => void
  selectPrevious?: () => void
  value: string | undefined
}

function cursorFor(element: HTMLInputElement | null) {
  return {
    isAt: (position: number) => {
      if (element === null) return false
      return (
        element.selectionStart === position && element.selectionEnd === position
      )
    }
  }
}

export const PartialInput = forwardRef(
  (
    {
      filterValue,
      id,
      maxLength,
      onChange,
      onKeyDown,
      placeholder,
      selectNext,
      selectPrevious,
      value,
      ...inputProps
    }: PartialInputProps,
    forwardedRef: ForwardedRef<HTMLInputElement>
  ) => {
    const internalRef = useRef<HTMLInputElement>(null)
    const textSizeClasses = useText({
      size: 'medium',
      weight: 'regular'
    })

    function handleChange(e: ChangeEvent<HTMLInputElement>) {
      // optionally filter out invalid input characters
      const newValue = filterValue?.(e.target.value) ?? e.target.value

      // if new value is too large, do nothing
      if (maxLength !== undefined && newValue.length > maxLength) {
        e.preventDefault()
        return
      }

      onChange(newValue)

      // if we just typed the max digit, and the caret is at the end of the input
      // then focus the next input
      if (
        maxLength !== undefined &&
        newValue.length === maxLength &&
        value?.length !== maxLength &&
        cursorFor(internalRef.current).isAt(maxLength)
      ) {
        selectNext?.()
      }
    }

    function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
      switch (e.key) {
        case 'ArrowRight':
          // If we try to move right when at end of input, focus next input
          if (cursorFor(e.currentTarget).isAt(e.currentTarget.value.length)) {
            e.preventDefault()
            selectNext?.()
          }
          break

        case 'ArrowLeft':
        case 'Backspace':
          // If we try to move left or delete when at start of input, focus previous input
          if (cursorFor(e.currentTarget).isAt(0)) {
            e.preventDefault()
            selectPrevious?.()
          }
          break

        default:
          break
      }

      onKeyDown?.(e)
    }

    return (
      <div className='partialInput'>
        {/* span is used to set the width for the parent element. This allows the input to always fit its content */}
        {/* the span itself isn't hidden, but it's always underneath (z-index wise) the input. Aria-hidden hides from screen readers */}
        <span aria-hidden>{value || placeholder || ''}</span>
        <input
          {...inputProps}
          className={`${InputFieldStyles.inputBase} ${textSizeClasses}`}
          id={id}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          ref={shareRef(internalRef, forwardedRef)}
          type='text'
          value={value ?? ''}
        />
      </div>
    )
  }
)
