import { Column, Row } from '@te-whatu-ora/anatomic'
import { InputQuantityProps } from './types'
import { InputDecimal } from '../decimalInput'

export function LoadingQuantityInput({
  disabled,
  inputId,
  onChangeValue,
  required,
  value
}: InputQuantityProps) {
  return (
    <Row>
      <Column columns={8}>
        <InputDecimal
          disabled={disabled}
          id={inputId}
          label=''
          name={`${inputId}-quantity`}
          onChange={onChangeValue}
          required={required}
          value={value?.value?.toString()}
        />
      </Column>
      <Column columns={4} />
    </Row>
  )
}
