import { Box, Heading, Stack } from '@te-whatu-ora/anatomic'
import { Show } from 'components/show'
import type { GroupInputProps } from '@healthnz-ult/fhir-engine-renderer/types'
import { Children, ComponentProps, useEffect, useRef, useState } from 'react'
import { Divider } from 'components/accessibleDivider'

export default function GroupInput({
  children,
  depth = 0,
  label,
  labelComponent
}: GroupInputProps) {
  const [isLastChild, setIsLastChild] = useState(false)
  const elementRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Gets the current element
    // Finds its parent (the group wrapped in the Anatomic stack) to see if the current element is the last one
    // Children has to be a dependency so the hook gets triggered when the parent changes
    const currentElement = elementRef.current
    setIsLastChild(
      !!currentElement && !currentElement.parentElement?.nextElementSibling
    )
  }, [children])

  return (
    <div ref={elementRef}>
      <Show when={Children.count(children) > 0}>
        <Show when={depth < 2 && (labelComponent || label)}>
          <Heading
            level='4'
            color='primary100'
            as={`h${depth + 1}` as ComponentProps<typeof Heading>['as']}
          >
            {labelComponent ?? label}
          </Heading>
          <Box marginTop='small' marginBottom='medium'>
            <Divider variant='light' />
          </Box>
        </Show>
        <Stack className='group-children-stack' space='small'>
          {children}
        </Stack>
        <Show when={depth >= 2 && !isLastChild}>
          <Box marginTop='small'>
            <Divider variant='light' />
          </Box>
        </Show>
      </Show>
    </div>
  )
}
