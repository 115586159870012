/* eslint-disable react/jsx-no-useless-fragment */

import { ReactNode } from 'react'

export interface Props {
  when: any
  children: ReactNode
  fallback?: ReactNode
}

export function Show({ children, when, fallback }: Props): JSX.Element | null {
  if (when) {
    return <>{children}</>
  }

  if (fallback) {
    return <>{fallback}</>
  }

  return null
}
