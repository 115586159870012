import {
  Code,
  Uri,
  ValueSetExpander,
  ValueSetResource
} from '@healthnz-ult/fhir-engine-renderer/types'
import { apiEndpointUrl, xApiKey } from 'env'

export function expandValueSet(
  questionnaireId: string,
  jwt: string
): ValueSetExpander {
  return (canonical, options) => {
    const url = new URL(
      `${apiEndpointUrl}/v1/nzhts/${questionnaireId}/valueset/expand?`
    )

    // url is the only required query param
    url.searchParams.set('url', canonical)

    // add options to query params
    Object.entries(options || {})
      .filter(([, value]) => value !== undefined) // filter unset keys
      .forEach(([key, value]) => url.searchParams.set(key, value))

    // if count not set in options, set a default to avoid overloading the endpoint
    if (!options?.count) {
      url.searchParams.set('count', '50')
    }

    return fetch(url, {
      headers: {
        'x-api-key': xApiKey,
        authorization: `Bearer ${jwt}`
      }
    })
      .then(res => res.json())
      .then((data: ValueSetResource) => {
        return {
          total: data.expansion?.total ?? 0,
          contains:
            data.expansion?.contains?.map(contain => ({
              system: contain.system as Uri | undefined,
              code: contain.code as Code | undefined,
              display: contain.display
            })) ?? []
        }
      })
      .catch(() => {
        throw new Error('Could not expand valueSet')
      })
  }
}
