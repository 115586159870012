import { Box, Heading, Text } from '@te-whatu-ora/anatomic'
import { Divider } from 'components/accessibleDivider'

export default function FourOhFourPage() {
  return (
    <>
      <Box paddingBottom='small'>
        <Heading level='2' as='h1' color='primary100'>
          404
        </Heading>
      </Box>
      <Box marginTop='small' marginBottom='medium'>
        <Divider variant='light' />
      </Box>
      <Box marginTop='small' marginBottom='medium'>
        <Text>This questionnaire doesn't exist</Text>
      </Box>
    </>
  )
}
