import { Outlet } from 'react-router-dom'

import '@te-whatu-ora/anatomic/styles'
import { ThemeProvider } from '@te-whatu-ora/anatomic'
import { webSelfService } from '@te-whatu-ora/anatomic-themes'
import { WatermarkContainer } from 'components/watermarkContainer'
import { getContainerCss } from './utils'

function Layout() {
  return (
    <ThemeProvider theme={webSelfService.className}>
      <WatermarkContainer>
        <div>
          {/* object destructuring gets around TS + lint warnings when using styled-jsx with CRA */}
          <style {...{ jsx: true }}>{getContainerCss()}</style>
          <div className='container'>
            <Outlet />
          </div>
        </div>
      </WatermarkContainer>
    </ThemeProvider>
  )
}

export default Layout
