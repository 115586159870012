import { useEffect, useState } from 'react'
import { IntersectionHeight } from './IntersectionHeight'
import { IntersectionOffset } from './IntersectionOffset'

export interface IntersectionMeasurement {
  height: number
  offset: number
}

export interface MeasureIntersectionProps {
  onMeasure: (measurement: IntersectionMeasurement) => void
}

export function MeasureIntersection({ onMeasure }: MeasureIntersectionProps) {
  const [height, setHeight] = useState<number>()
  const [offset, setOffset] = useState<number>()

  // 1 / ratio = the number of times the intersection "fits" into the container
  // Rounding up makes sure that when the amount is a decimal, we err on too many
  const sectionCount = height
    ? Math.ceil(window.innerHeight / height)
    : undefined

  useEffect(() => {
    if (height === undefined) return
    if (offset === undefined) return

    onMeasure({ height, offset })
  }, [height, onMeasure, offset])

  return (
    <>
      <IntersectionHeight onMeasure={setHeight} />
      {sectionCount && (
        <IntersectionOffset sectionCount={sectionCount} onMeasure={setOffset} />
      )}
    </>
  )
}
