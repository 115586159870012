import {
  Coding,
  Decimal,
  QuantityInputProps
} from '@healthnz-ult/fhir-engine-renderer/types'
import { useAdditionalLabelProperties } from 'components/fhirEngine/utils/additionalProperties'
import { useEffect, useState } from 'react'
import OpenQuantityInput from './OpenQuantityInput'
import ChoiceQuantityInput from './ChoiceQuantityInput'
import { InputQuantityProps } from './types'
import { LoadingQuantityInput } from './LoadingQuantityInput'
import SingleQuantityInput from './SingleQuantityInput'
import { InputFieldset } from '../inputFieldset'

function Input(props: InputQuantityProps) {
  // eslint-disable-next-line react/destructuring-assignment
  switch (props.options?.length) {
    case undefined:
      return <LoadingQuantityInput {...props} />
    case 0:
      return <OpenQuantityInput {...props} />
    case 1:
      return <SingleQuantityInput {...props} />
    default:
      return <ChoiceQuantityInput {...props} />
  }
}

export default function QuantityInput(props: QuantityInputProps) {
  const {
    disabled,
    error,
    getUnitOptions,
    helperText,
    inputId,
    onBlur,
    onChange,
    onChangeValue,
    onChangeUnit,
    required,
    value
  } = props

  const [options, setOptions] = useState<Coding[]>()

  useEffect(() => {
    getUnitOptions().then(res => {
      setOptions(res.options)
    })
  }, [getUnitOptions, setOptions])

  function handleChangeValue(newValue: string) {
    const parsedValue = Number.parseFloat(newValue)

    if (Number.isNaN(parsedValue) || !Number.isFinite(parsedValue)) {
      return onChangeValue(null)
    }

    onChangeValue(parsedValue as Decimal)
  }

  function handleBlur(event: React.FocusEvent) {
    if (event.currentTarget.contains(event.relatedTarget)) return
    onBlur()
  }

  const additionalProps = useAdditionalLabelProperties(props)

  return (
    <InputFieldset
      {...additionalProps}
      disabled={disabled}
      errorMessage={error}
      helperText={helperText}
    >
      <div onBlur={handleBlur}>
        <Input
          disabled={disabled}
          getUnitOptions={getUnitOptions}
          inputId={inputId}
          onChange={onChange}
          onChangeUnit={onChangeUnit}
          onChangeValue={handleChangeValue}
          options={options}
          required={required}
          value={value}
        />
      </div>
    </InputFieldset>
  )
}
