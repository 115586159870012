import { Card, Heading, Icon, Text } from '@te-whatu-ora/anatomic'
import type { Reference } from '@healthnz-ult/fhir-engine-renderer/types'

import 'components/fhirEngine/referenceInput/styles.css'

interface ReferenceCardProps {
  displayValue: Reference
  onRemove: (reference: Reference) => void
}

export default function ReferenceCard({
  displayValue,
  onRemove
}: ReferenceCardProps) {
  return (
    <Card className='reference-card'>
      <Icon
        aria-label={`Remove reference answer: "${displayValue.reference}, ${displayValue.display}"`}
        className='reference-card__remove-button'
        flexShrink={0}
        icon='cross'
        onClick={() => onRemove(displayValue)}
        variant='tagIcon'
      />
      <Heading level='4' weight='weak'>
        {`Reference: ${displayValue.reference ?? '-'}`}
      </Heading>
      <Text className='reference-card__display'>
        {displayValue.display ?? '-'}
      </Text>
    </Card>
  )
}
