import { Box, InputMessage } from '@te-whatu-ora/anatomic'
import { InputLegend } from './InputLegend'

import './styles.css'

// TODO: FIX ANATOMIC TYPES
type InputLegendProps = {
  subheading?: any
  error?: any
  disabled?: any
  required?: any
  href?: any
  tertiaryLabelAs?: any
  tertiaryLabelIcon?: any
  tertiaryLabelIconPosition?: any
}

export interface InputFieldsetProps
  extends Pick<
    InputLegendProps,
    | 'subheading'
    | 'error'
    | 'disabled'
    | 'required'
    | 'href'
    | 'tertiaryLabelAs'
    | 'tertiaryLabelIcon'
    | 'tertiaryLabelIconPosition'
  > {
  children: React.ReactNode
  errorMessage?: string
  helperText?: string
  label?: string
  labelComponent?: React.ReactNode
}

export function InputFieldset({
  children,
  error,
  errorMessage,
  helperText,
  label,
  labelComponent,
  ...rest
}: InputFieldsetProps) {
  return (
    <Box flexGrow={1}>
      <fieldset className='input-fieldset'>
        <InputLegend
          {...rest}
          label={label}
          labelComponent={labelComponent}
          error={error ?? !!errorMessage}
        />
        {children}
        <InputMessage errorMessage={errorMessage} helperText={helperText} />
      </fieldset>
    </Box>
  )
}
