import { Loader as AnatomicLoader } from '@te-whatu-ora/anatomic'
import { useEffect } from 'react'

export interface LoaderProps {
  message: string
}

export function Loader({ message }: LoaderProps) {
  useEffect(() => {
    document.getElementById('loader')?.focus()
  }, [])

  return (
    <div
      id='loader'
      className='loader'
      aria-label='submitting information'
      aria-live='assertive'
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
    >
      <AnatomicLoader message={message} />
    </div>
  )
}
