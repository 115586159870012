/* eslint-disable react/destructuring-assignment */
import { Column, Row } from '@te-whatu-ora/anatomic'
import { Coding, Quantity } from '@healthnz-ult/fhir-engine-renderer/types'
import { useRef } from 'react'
import { SelectInstance } from 'react-select'
import { InputDecimal } from '../decimalInput'
import { SearchInput } from '../select/SearchableSelect'
import { InputQuantityProps } from './types'

function UnitFromQuantity(value: Quantity | null): Coding | null {
  if (!value) return null
  if (!value.system && !value.code && !value.unit) return null

  return {
    display: value.unit,
    system: value.system,
    code: value.code
  }
}

export default function ChoiceQuantityInput({
  disabled,
  getUnitOptions,
  inputId,
  onChangeValue,
  onChangeUnit,
  required,
  value
}: InputQuantityProps) {
  const decimalRef = useRef<HTMLInputElement>(null)
  const unitRef = useRef<SelectInstance<any>>(null)

  return (
    <Row gutter='xsmall'>
      <Column columns={8}>
        <InputDecimal
          disabled={disabled}
          id={inputId}
          label=''
          name={`${inputId}-quantity`}
          onChange={onChangeValue}
          required={required}
          value={value?.value?.toString()}
          ref={decimalRef}
        />
      </Column>
      <Column columns={4}>
        <SearchInput
          getOptions={getUnitOptions}
          name={`${inputId}-unit`}
          onChange={onChangeUnit}
          placeholder='unit'
          value={UnitFromQuantity(value)}
          ref={unitRef}
        />
      </Column>
    </Row>
  )
}
