import { ForwardedRef, KeyboardEvent, forwardRef } from 'react'
import { PartialInput, PartialInputProps } from './PartialInput'
import { stringToInteger } from '../utils/numberUtils'

export type FromOrdinalFn = (ordinal: number | undefined) => string | undefined
export type ToOrdinalFn = (value: string | undefined) => number | undefined

export interface PartialSpinButtonProps
  extends Omit<PartialInputProps, 'inputMode'> {
  fromOrdinal?: FromOrdinalFn
  inputMode?: PartialInputProps['inputMode']
  max?: number
  min?: number
  toOrdinal?: ToOrdinalFn
}

function clampBetween(min: number | undefined, max: number | undefined) {
  return (value: number | undefined): number | undefined => {
    if (value === undefined) return undefined
    if (min !== undefined && value < min) return min
    if (max !== undefined && value > max) return max
    return value
  }
}

const defaultFromOrdinal = (maxLength: number | undefined): FromOrdinalFn => {
  return (ordinal: number | undefined): string | undefined => {
    if (Number.isNaN(ordinal) || ordinal === undefined) return undefined

    return ordinal.toString().padStart(maxLength || 0, '0')
  }
}

const defaultToOrdinal: ToOrdinalFn = value => {
  if (value === undefined) return undefined
  const integer = Number.parseInt(value, 10)
  if (Number.isNaN(integer)) return undefined
  return integer
}

const defaultFilterValue = (newValue: string) => {
  return stringToInteger(newValue, {
    preserveSign: false,
    dropLeadingZeros: false
  })
}

export const PartialSpinButton = forwardRef(
  (
    {
      filterValue = defaultFilterValue,
      fromOrdinal: propsFromOrdinal,
      inputMode = 'numeric',
      max,
      maxLength,
      min,
      onChange,
      onKeyDown,
      toOrdinal = defaultToOrdinal,
      value,
      ...rest
    }: PartialSpinButtonProps,
    forwardedRef: ForwardedRef<HTMLInputElement>
  ) => {
    const clamp = clampBetween(min, max)
    const fromOrdinal: FromOrdinalFn =
      propsFromOrdinal ?? defaultFromOrdinal(maxLength)

    function onChangeOrdinal(ordinal: number | undefined) {
      if (ordinal === undefined) return
      const newValue = fromOrdinal(ordinal)
      if (newValue === undefined) return
      onChange(newValue)
    }

    function increment() {
      // if no value to increment, default to min value
      if (value === undefined || value === '') return onChangeOrdinal(min)

      // if we can't convert to ordinal, we can't increment
      const ordinal = toOrdinal(value)
      if (ordinal === undefined) return

      // increment ordinal and clamp it between min + max
      onChangeOrdinal(clamp(ordinal + 1))
    }

    function decrement() {
      // if no value to decrement, default to max value
      if (value === undefined || value === '') return onChangeOrdinal(max)

      // if we can't convert to ordinal, we can't decrement
      const ordinal = toOrdinal(value)
      if (ordinal === undefined) return

      // decrement ordinal and clamp it between min + max
      onChangeOrdinal(clamp(ordinal - 1))
    }

    function handleChange(newValue: string) {
      const ordinal = toOrdinal(newValue)
      const clamped = clamp(ordinal)

      if (clamped !== ordinal) {
        // if newValue is 0 and isn't maxLength, then there's a chance the user is
        // typing in leading 0s for a non-0 value
        if (ordinal !== 0 || (maxLength && newValue.length === maxLength)) {
          return onChangeOrdinal(clamped)
        }
      }

      onChange(newValue)
    }

    function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
      switch (e.key) {
        case 'ArrowUp': {
          e.preventDefault()
          increment()
          break
        }

        case 'ArrowDown': {
          e.preventDefault()
          decrement()
          break
        }

        // case 'Home': TODO: go to minimum value
        // case 'End': TODO: go to maximum value
        default:
          break
      }

      onKeyDown?.(e)
    }

    return (
      <PartialInput
        {...rest}
        filterValue={filterValue}
        inputMode={inputMode}
        maxLength={maxLength}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        value={value}
        ref={forwardedRef}
      />
    )
  }
)
