import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useMemo
} from 'react'

type SubmitState = 'success' | 'loading' | 'ready'

interface SubmitStateProviderProps {
  changeSubmitState(newState: SubmitState): void
  submitState: SubmitState
}

const SubmitStateContext = createContext<SubmitStateProviderProps>(
  {} as SubmitStateProviderProps
)

export const SubmitStateProvider = ({ children }: { children: ReactNode }) => {
  const [submitState, setSubmitState] = useState<SubmitState>('ready')

  const value = useMemo(() => {
    function changeSubmitState(newState: SubmitState) {
      setSubmitState(newState)
    }

    return { submitState, changeSubmitState }
  }, [submitState, setSubmitState])

  return (
    <SubmitStateContext.Provider value={value}>
      {children}
    </SubmitStateContext.Provider>
  )
}

export const useSubmitState = () => useContext(SubmitStateContext)
