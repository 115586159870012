import { DateInputProps } from '@healthnz-ult/fhir-engine-renderer/types'
import { InputDate } from './InputDate'
import { InputDateValue, toInputDateValue, toDateString } from './utils'

// Wrap the Anatomic-style InputInteger in a FHIR adapter
export default function DateInput({
  error,
  inputId,
  label,
  labelComponent,
  onBlur,
  onChange,
  required,
  value,
  helperText
}: DateInputProps) {
  function handleChange(newValue: InputDateValue) {
    onChange(toDateString(newValue))
  }

  return (
    <InputDate
      errorMessage={error}
      helperText={helperText}
      label={label}
      labelComponent={labelComponent}
      name={inputId}
      required={!labelComponent && required}
      onBlur={onBlur}
      onChange={handleChange}
      value={toInputDateValue(value)}
    />
  )
}
