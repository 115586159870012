import { InputText } from '@te-whatu-ora/anatomic'
import type { StringInputProps } from '@healthnz-ult/fhir-engine-renderer/types'
import { useAdditionalLabelProperties } from 'components/fhirEngine/utils/additionalProperties'
import { ChangeEvent } from 'react'
import { RequiredInputWrapper } from 'components/fhirEngine/utils/requiredLabel'

export default function StringInput(props: StringInputProps) {
  const {
    inputId,
    onChange,
    onFocus,
    error,
    helperText,
    onBlur,
    value,
    disabled,
    labelComponent,
    required
  } = props

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    onChange(event.target.value || null)
  }

  const additionalProps = useAdditionalLabelProperties(props)

  return (
    <RequiredInputWrapper
      inputId={inputId}
      labelComponent={labelComponent}
      required={required}
    >
      <InputText
        {...additionalProps}
        disabled={disabled}
        errorMessage={error}
        helperText={helperText}
        name={inputId}
        onBlur={onBlur}
        onChange={handleChange}
        onFocus={onFocus}
        type='text'
        value={value ?? undefined}
      />
    </RequiredInputWrapper>
  )
}
