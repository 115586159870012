import { Button, Column, InputField, Row } from '@te-whatu-ora/anatomic'
import React, { ChangeEvent, FocusEvent, useRef, useState } from 'react'

import {
  createReferenceResult,
  InputReferenceProps
} from 'components/fhirEngine/referenceInput'

import 'components/fhirEngine/referenceInput/styles.css'

export default function InputReferenceSearch({
  error,
  handleSearch,
  inputId,
  onAdd,
  onBlur,
  onFocus,
  referenceResource,
  required,
  setPrivateError
}: InputReferenceProps) {
  const buttonRef = useRef<HTMLButtonElement>(null)

  const [inputValue, setInputValue] = useState<string>('')

  function handleBlur(e: FocusEvent<HTMLInputElement>) {
    // Don't trigger the onBlur if the search button is clicked
    if (e.relatedTarget === buttonRef.current) return
    onBlur()
  }

  function handleChange(event: ChangeEvent<HTMLInputElement>) {
    setInputValue(event.target.value)
  }

  async function onSearch() {
    const result = await handleSearch(inputValue)

    if (!result) return
    if (!result.id) {
      setPrivateError('No result found')
    } else {
      const refResult = createReferenceResult(result, referenceResource)
      onAdd(refResult)
    }
  }

  return (
    <Row noGutters>
      <Column columns={11}>
        <InputField
          className='reference-input__input-field'
          error={!!error}
          name={inputId}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={onFocus}
          required={required}
          type='text'
          value={inputValue}
        />
      </Column>
      <Column columns={1} display='flex' alignItems='flexEnd'>
        <Button
          className='reference-input__reference-button'
          icon='search'
          onClick={onSearch}
          ref={buttonRef}
          variant='primary'
        />
      </Column>
    </Row>
  )
}
