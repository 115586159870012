import {
  Coding,
  SearchableSelectProps,
  SearchResponse
} from '@healthnz-ult/fhir-engine-renderer/types'
import { Box, InputMessage } from '@te-whatu-ora/anatomic'
import { InputLabel } from 'components/fhirEngine/inputLabel/InputLabel'
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate'
import { GroupBase, SelectInstance, SingleValue } from 'react-select'
import { forwardRef, useState } from 'react'
import customComponents from './components'

export interface Option {
  label: string
  value: Coding
}

function toOption(value: Coding): Option {
  return {
    label: value.display ?? value.code ?? '',
    value
  }
}

interface SearchInputProps {
  getOptions: (
    filter: string | undefined,
    pageSize?: number | undefined
  ) => Promise<SearchResponse>
  name: string
  onBlur?: () => void
  onChange: (newValue: Coding | null) => void
  onFocus?: () => void
  placeholder?: string
  value: Coding | null
}

export const SearchInput = forwardRef<SelectInstance<Option>, SearchInputProps>(
  (
    { getOptions, name, onBlur, onFocus, onChange, placeholder, value },
    ref
  ) => {
    const [total, setTotal] = useState<number>()

    const handleSearch: LoadOptions<
      Option,
      GroupBase<Option>,
      (() => Promise<SearchResponse>) | undefined
    > = async (searchTerm, loadedOptions, additional) => {
      // start a new search or get next page in existing search
      const searchResult = additional ? additional() : getOptions(searchTerm)

      return searchResult.then(result => {
        if (!total && result.total) setTotal(result.total)

        return {
          options: result.options.map(toOption),
          hasMore: result.nextPage !== undefined,
          additional: result.nextPage
        }
      })
    }

    function handleChange(newValue: SingleValue<Option>) {
      onChange(newValue?.value ?? null)
    }

    // only show DropdownIndicator if there are 100 or fewer options
    const DropdownIndicator =
      total && total <= 100 ? customComponents.DropdownIndicator : null

    return (
      <AsyncPaginate
        components={{
          ...customComponents,
          DropdownIndicator,
          ClearIndicator: () => null,
          IndicatorSeparator: () => null
        }}
        defaultOptions
        inputId={name}
        isClearable
        isMulti={false}
        loadOptions={handleSearch}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={handleChange}
        onFocus={onFocus}
        selectRef={ref}
        required
        value={value ? toOption(value) : null}
      />
    )
  }
)

export default function SearchableSelect(props: SearchableSelectProps) {
  const {
    error,
    helperText,
    inputId,
    onBlur,
    onChange,
    onFocus,
    getOptions,
    value
  } = props

  return (
    <Box>
      <InputLabel {...props} />
      <SearchInput
        getOptions={getOptions}
        name={inputId}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        value={value}
      />
      <InputMessage errorMessage={error} helperText={helperText} />
    </Box>
  )
}
