import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from 'react-router-dom'

export interface RouterProps {
  children: React.ReactNode
}

export function Router({ children }: RouterProps) {
  const router = createBrowserRouter(createRoutesFromElements(children))

  return <RouterProvider router={router} />
}
