import { createContext, useContext, useEffect, useMemo, ReactNode } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import { appEnvironment } from 'env'
import { ExpiredAuthError, ForbiddenError, GenericError } from 'lib/error'

const AUTH_PARAM_NAME = 'token'
const AUTH_SESSION_KEY = 'authToken'

export const AuthContext = createContext<string | undefined>(undefined)

export interface AuthProviderProps {
  children: React.ReactNode | ((authToken: string) => React.ReactNode)
}

function isTokenValid(token: string) {
  try {
    const decoded = jwtDecode(token)
    const currentTime = Math.floor(Date.now() / 1000)
    return decoded.exp && currentTime <= decoded.exp
  } catch {
    return false
  }
}

export function AuthProvider({ children }: AuthProviderProps): JSX.Element {
  const navigate = useNavigate()

  // we use a memo over an effect bc we need this code to run once and ideally on first render
  // we need it to run once because we remove the query param making it non-idempotent
  const authToken = useMemo(() => {
    // use fake token to simplify local dev
    if (appEnvironment === 'local') return 'token'

    // if query param present, overwrite stored token - even if invalid
    const paramToken = new URL(window.location.href).searchParams.get(
      AUTH_PARAM_NAME
    )
    if (paramToken) {
      sessionStorage.setItem(AUTH_SESSION_KEY, paramToken)
    }

    // use stored token if present + valid
    const storedToken = sessionStorage.getItem(AUTH_SESSION_KEY)
    if (!storedToken) throw new ForbiddenError(undefined, false)
    if (!isTokenValid(storedToken)) throw new ExpiredAuthError()
    return storedToken
  }, [])

  useEffect(() => {
    // remove token from URL if present
    // we do this in a useEffect instead of the useMemo where we get it as Next's navigate() only works
    // in a useEffect and it's not critical that it's removed on first render
    const newUrl = new URL(window.location.href)
    if (newUrl.searchParams.has(AUTH_PARAM_NAME)) {
      newUrl.searchParams.delete(AUTH_PARAM_NAME)
      navigate(
        {
          pathname: newUrl.pathname,
          search: createSearchParams(newUrl.searchParams).toString()
        },
        { replace: true }
      ) // replace to avoid reloading page
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, navigate])

  if (!authToken) {
    throw new ForbiddenError()
  }

  return (
    <AuthContext.Provider value={authToken}>
      {typeof children === 'function' ? children(authToken) : children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext)

  if (context === undefined) {
    throw new GenericError()
  }

  return context
}

export function WithAuthProvider<T extends Object>(
  Component: (props: { authToken: string } & T) => ReactNode
) {
  return (props: T) => (
    <AuthProvider>
      {authToken => <Component {...props} authToken={authToken} />}
    </AuthProvider>
  )
}
