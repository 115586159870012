import { Card } from '@te-whatu-ora/anatomic'
import React, { PropsWithChildren } from 'react'
import { Loader } from 'components/loader'

interface ContentLoaderProps extends PropsWithChildren {
  loadingMessage?: string
  state: 'loading' | string
}

export default function ContentLoader({
  children,
  loadingMessage = 'Loading',
  state
}: ContentLoaderProps) {
  if (state === 'loading') {
    return (
      <Card>
        <Loader message={loadingMessage} />
      </Card>
    )
  }

  return children
}
