import { CommonInputProps } from '@healthnz-ult/fhir-engine-renderer/types'
import { InputLabel as InputLabelAnatomic } from '@te-whatu-ora/anatomic'
import { useAdditionalLabelProperties } from 'components/fhirEngine/utils/additionalProperties'
import { RequiredInputWrapper } from 'components/fhirEngine/utils/requiredLabel'

export function InputLabel({
  disabled,
  error,
  inputId,
  label,
  labelComponent,
  required,
  supportLink,
  supportModal
}: Partial<CommonInputProps<any>>) {
  const additionalProps = useAdditionalLabelProperties({
    label,
    labelComponent,
    supportLink,
    supportModal,
    required
  })

  return (
    <RequiredInputWrapper
      inputId={inputId || ''}
      labelComponent={labelComponent}
      required={required}
    >
      <InputLabelAnatomic
        {...additionalProps}
        disabled={disabled}
        error={!!error}
        htmlFor={inputId}
      />
    </RequiredInputWrapper>
  )
}
