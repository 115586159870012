import { SubmitModalProps } from '@healthnz-ult/fhir-engine-renderer/types'
import { Box, Button, Stack } from '@te-whatu-ora/anatomic'
import { BaseModal } from 'components/shared/baseModal'
import React from 'react'
import { useSubmitState } from 'contexts/submitState/submitState'

const defaultSubmitButton = 'Submit response'
const defaultTitle = 'Submit questionnaire response'

export function SubmitModal({
  children,
  onClose,
  onSubmit,
  submitButtonLabel,
  title
}: SubmitModalProps) {
  const { changeSubmitState, submitState } = useSubmitState()

  const handleSubmit =
    onSubmit &&
    (async () => {
      changeSubmitState('loading')

      onSubmit().then((res: string) => {
        switch (res) {
          case 'error':
            // TODO: handle errors
            return changeSubmitState('ready')
          case 'invalid':
            return changeSubmitState('ready')
          case 'success':
            return changeSubmitState('success')
          default:
            return changeSubmitState('ready')
        }
      })
    })

  const handleOnConfirm = () => {
    handleSubmit?.()
    onClose()
  }

  return (
    <BaseModal
      onClose={onClose}
      title={title || submitButtonLabel || defaultTitle}
    >
      <div>
        <Stack space='small'>{children}</Stack>

        <Box alignItems='center' display='flex' flexDirection='column'>
          <Button
            className='mt-12'
            onClick={handleOnConfirm}
            disabled={submitState === 'loading'}
            variant='primary'
          >
            {`Yes, ${(
              submitButtonLabel ||
              title ||
              defaultSubmitButton
            ).toLowerCase()}`}
          </Button>

          <Button className='mt-4' onClick={onClose} variant='secondary'>
            No, back to form
          </Button>
        </Box>
      </div>
    </BaseModal>
  )
}
