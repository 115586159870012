import { InputLabel } from 'components/fhirEngine/inputLabel/InputLabel'
import { ChangeEvent, useRef } from 'react'
import { AttachmentInputProps } from '@healthnz-ult/fhir-engine-renderer/types'
import { Box, Button, InputMessage, Stack } from '@te-whatu-ora/anatomic'
import { pdfjs } from 'react-pdf'
import { FilePreview } from './FilePreview'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

export interface InputAttachmentProps {
  onChange?: (value: File) => void
  value?: File
}

export function AttachmentInput(props: AttachmentInputProps) {
  const { disabled, error, helperText, inputId, isLoading, onChange, value } =
    props

  const fileInputRef = useRef<HTMLInputElement | null>(null)

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0]
    if (onChange) onChange(file)
  }

  const openFileDialog = () => {
    if (fileInputRef.current) fileInputRef.current.click()
  }

  return (
    <Box role='group' aria-labelledby={inputId}>
      <InputLabel {...props} />
      <Stack space='xsmall'>
        <FilePreview file={value} isLoading={isLoading} />
        <input
          disabled={disabled}
          id={inputId}
          onChange={handleFileChange}
          ref={fileInputRef}
          style={{ display: 'none' }}
          type='file'
        />
        <Button
          aria-describedby={
            // TODO: replace 'file' with specific file type accepted
            value ? `Replace selected file: ${value.name}` : 'Upload file'
          }
          disabled={disabled}
          icon='arrow_up'
          iconPosition='left'
          onPress={openFileDialog}
          variant='tertiary'
        >
          {/* TODO: replace 'file' with specific file type accepted */}
          {value ? 'Replace file' : 'Upload file'}
        </Button>
      </Stack>
      <InputMessage errorMessage={error} helperText={helperText} />
    </Box>
  )
}
