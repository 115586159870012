import type { ReferenceMultiInputProps } from '@healthnz-ult/fhir-engine-renderer/types'

import { CommonInput } from 'components/fhirEngine/referenceInput'

export default function ReferenceMultiInput(props: ReferenceMultiInputProps) {
  const {
    error,
    inputId,
    label,
    onAdd,
    onBlur,
    onFocus,
    onRemove,
    referenceResource,
    required,
    value
  } = props

  return (
    <CommonInput
      error={error}
      handleRemove={onRemove}
      inputId={inputId}
      label={label}
      onAdd={onAdd}
      onBlur={onBlur}
      onFocus={onFocus}
      referenceResource={referenceResource}
      required={required}
      value={value}
    />
  )
}
