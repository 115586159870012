import type { Reference } from '@healthnz-ult/fhir-engine-renderer/types'

import { apiEndpointUrl } from 'env'

import { Name, User } from './types'

// Regular Expressions for HPI Identifiers
const CPN_FORMAT = /^\d{2}[A-Z]{4}$/
const ORG_ID_FORMAT = /^G[A-Z0-9]{2}\d{3}-[A-Z0-9]$/
const FAC_ID_FORMAT = /^F[A-Z0-9]{2}\d{3}-[A-Z0-9]$/

// Regular Expressions for NHI Numbers
const CURRENT_NHI_FORMAT = /^[A-Z]{3}\d{3}\d$/
const NEW_NHI_FORMAT = /^[A-Z]{3}\d{2}[A-Z][A-Z]$/

export enum ResourceType {
  Patient = 'Patient',
  Practitioner = 'Practitioner'
}

// Returns a return type check
export function isResourceType(resource: string, type: ResourceType): boolean {
  return resource === type
}

// Creates the reference value to be displayed and sent to the QResponse
export function createReferenceValue(resource: string, value: string): string {
  return `${
    isResourceType(resource, ResourceType.Patient) ? 'Patient' : 'Practitioner'
  }/${value}`
}

// Create the reference user's name
export function createReferenceName(names?: Name[]): string {
  if (!names) return ''
  const name = names[0]
  const { prefix } = name
  return `${prefix ? `${prefix.join(' ')} ` : ''}${name.given.join(' ')} ${
    name.family
  }`
}

// Create the label for the input reference multi dropdown options
export function createLabelReferenceDropdown(user: User) {
  const { birthDate, gender, name } = user
  const birthdayDisplay = birthDate ? `-${birthDate}` : ''
  const genderDisplay = gender ? `-${gender}` : ''
  const nameDisplay = createReferenceName(name)
  return `${nameDisplay}${birthdayDisplay}${genderDisplay}`
}

// Creates the reference display to be displayed and sent to the QResponse
export function createReferenceDisplay(user: User): string {
  const { birthDate, gender, name } = user
  const birthdayDisplay = birthDate ? `\nBirthdate: ${birthDate}` : ''
  const genderDisplay = gender ? `\nGender: ${gender}` : ''
  const nameDisplay = `Name: ${createReferenceName(name)}`
  return `${nameDisplay}${birthdayDisplay}${genderDisplay}`
}

// Creates the reference to be displayed and sent to the QResponse
export function createReferenceResult(res: User, resource: string): Reference {
  return {
    display: createReferenceDisplay(res),
    reference: createReferenceValue(resource, res.id || '')
  }
}

interface GetReferenceProps {
  questionnaireId?: string
  referenceResource: string
  searchById: boolean
  value: string
}

// Create the url to query reference data from
export function getReferenceUrl({
  questionnaireId,
  referenceResource,
  searchById,
  value
}: GetReferenceProps) {
  // Returns url in the shape of:
  // {{FHIR_QUESTIONNAIRE_API_URL}}/v1/hip/{{Identifier}}/{{patient | practitioner}}/{{ID}} or
  // {{FHIR_QUESTIONNAIRE_API_URL}}/v1/hip/{{Identifier}}/practitioner/search?name={{NAME}}
  const refSub = isResourceType(referenceResource, ResourceType.Patient)
    ? 'patient'
    : 'practitioner'
  return `${apiEndpointUrl}/v1/hip/${questionnaireId}/${refSub}${
    !searchById ? '/search?name=' : '/'
  }${value}`
}

// Check the validity of a HPI
export function isValidHPIIdentifier(hpiIdentifier: string): boolean {
  return (
    CPN_FORMAT.test(hpiIdentifier) ||
    ORG_ID_FORMAT.test(hpiIdentifier) ||
    FAC_ID_FORMAT.test(hpiIdentifier)
  )
}

// Check the validity of a NHI
export function isValidNHINumber(nhiNumber: string): boolean {
  return CURRENT_NHI_FORMAT.test(nhiNumber) || NEW_NHI_FORMAT.test(nhiNumber)
}

// Validates the reference input value
export function validateReferenceInputValue(
  resource: string,
  byId: boolean,
  value?: string
): string | undefined {
  if (!value) return 'Required'

  if (isResourceType(resource, ResourceType.Patient)) {
    return isValidNHINumber(value)
      ? undefined
      : 'The provided NHI number is invalid. Please follow the format AAANNNA or AAANNAX.'
  }

  if (byId) {
    return isValidHPIIdentifier(value)
      ? undefined
      : 'The provided HPI number is invalid. Please follow the appropriate format for CPN, HPI ORG ID, or HPI FAC ID.'
  }

  return undefined
}
