import { ModalProps } from '@healthnz-ult/fhir-engine-renderer/types'
import { Dialog } from '@te-whatu-ora/anatomic'
import { ReactNode } from 'react'
import { ControllableDialogRoot } from '../controllableDialogRoot'

interface BaseModalProps extends Omit<ModalProps, 'formItems'> {
  children: ReactNode
}

export function BaseModal({ children, onClose, title }: BaseModalProps) {
  return (
    <ControllableDialogRoot
      modal
      heading={title}
      onClose={onClose}
      trigger={
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        <button
          data-testid='modal-trigger'
          id='modal-trigger'
          style={{ visibility: 'hidden' }}
          type='button'
        />
      }
    >
      <Dialog.Content>{children}</Dialog.Content>
    </ControllableDialogRoot>
  )
}
