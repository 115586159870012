import { Box, Button, Card, Stack } from '@te-whatu-ora/anatomic'
import {
  RepeatedItemsListProps,
  isGroupItem
} from '@healthnz-ult/fhir-engine-renderer/types'
import { Show } from 'components/show'
import { Divider } from 'components/accessibleDivider'
import { InputFieldset } from '../inputFieldset'

export default function RepeatedItemsList({
  canAdd,
  children,
  item,
  onAdd
}: RepeatedItemsListProps) {
  if (isGroupItem(item)) {
    // force children into 1D array via flatMap
    const childrenArray = [children].flatMap(child => child)

    return (
      <Stack space='xsmall'>
        {childrenArray.map((child, index) => (
          <Card>
            {child}
            <Show when={canAdd && index === childrenArray.length - 1}>
              <Box marginTop='medium' marginBottom='small'>
                <Divider variant='light' />
              </Box>
              <Button onPress={onAdd} disabled={!canAdd} variant='link'>
                Add another group
              </Button>
            </Show>
          </Card>
        ))}
      </Stack>
    )
  }

  return (
    <InputFieldset
      disabled={item.readOnly}
      label={item.text || ''}
      required={item.required}
    >
      <Stack space='xsmall'>{children}</Stack>
      <Show when={canAdd}>
        <Button onPress={onAdd} variant='link'>
          Add another value
        </Button>
      </Show>
    </InputFieldset>
  )
}
