import { Box, InputMessage } from '@te-whatu-ora/anatomic'
import { InputLabel } from 'components/fhirEngine/inputLabel/InputLabel'
import ReactSelect, { SingleValue } from 'react-select'
import { SelectProps } from '@healthnz-ult/fhir-engine-renderer/types'
import React from 'react'
import { Option } from './type'
import customComponents from './components'

export default function Select(props: SelectProps) {
  const {
    error,
    helperText,
    inputId,
    onBlur,
    onChange,
    onFocus,
    options,
    value
  } = props

  function handleChange(newValue: SingleValue<Option>) {
    onChange(newValue?.value ?? null)
  }

  return (
    <Box>
      <InputLabel {...props} />
      <ReactSelect
        components={customComponents}
        inputId={inputId}
        isClearable
        isSearchable={options.length >= 20}
        isMulti={false}
        onBlur={onBlur}
        onChange={handleChange}
        onFocus={onFocus}
        options={options}
        required
        value={value !== null ? options[value] : undefined}
      />
      <InputMessage errorMessage={error} helperText={helperText} />
    </Box>
  )
}
