import {
  Column,
  InputFieldStyles,
  InputText,
  Row,
  useText
} from '@te-whatu-ora/anatomic'
import { ChangeEvent } from 'react'
import { InputDecimal } from '../decimalInput'
import { InputQuantityProps } from './types'

export default function OpenQuantityInput({
  disabled,
  inputId,
  onChangeValue,
  onChangeUnit,
  required,
  value
}: InputQuantityProps) {
  const textSizeClasses = useText({
    size: 'medium',
    weight: 'regular'
  })

  function handleOpenChange(event: ChangeEvent<HTMLInputElement>) {
    onChangeUnit(event.target.value)
  }

  return (
    <Row gutter='xsmall'>
      <Column columns={8}>
        <InputDecimal
          disabled={disabled}
          id={inputId}
          label=''
          name={`${inputId}-quantity`}
          onChange={onChangeValue}
          required={required}
          value={value?.value?.toString()}
        />
      </Column>
      <Column columns={4}>
        <InputText
          className={`${InputFieldStyles.inputBase} ${textSizeClasses}`}
          disabled={disabled}
          label=''
          name={`${inputId}-unit`}
          onChange={handleOpenChange}
          placeholder='unit'
          required={required}
          type='text'
          value={value?.unit}
        />
      </Column>
    </Row>
  )
}
