// at least 1 '0' and no other numerals are present
export const IS_ZERO = /^[^1-9]*0[^1-9]*$/

// a '-' appears before any numerals
export const IS_NEGATIVE = /^[^0-9]*-[\s\S]*$/

// a '.' appears anywhere
export const IS_DECIMAL = /\./

// A very permissive function which takes any string and converts it into an integer string
export function stringToInteger(
  value: string,
  {
    preserveSign = true, // keep the `-` at the front of the integer string
    dropLeadingZeros = true, // ie. remove `0000` from `00001`
    allowNegativeZero = false // if false replace `-0` with `0`
  } = {}
) {
  const isZero = IS_ZERO.test(value)
  const isNegative = IS_NEGATIVE.test(value)

  // remove all non-numeric characters
  let numerals = value.replaceAll(/[^0-9]+/g, '')

  // Zero is a special case
  if (isZero) {
    // optionally drop extra zeros but keep at least 1
    if (dropLeadingZeros) numerals = numerals.replace(/^0+/, '0')
    if (isNegative && allowNegativeZero) numerals = `-${numerals}`
    return numerals
  }

  // non-zero values
  if (dropLeadingZeros) numerals = numerals.replace(/^0+/, '')
  if (isNegative && preserveSign) numerals = `-${numerals}`
  return numerals
}

// A very permissive function which takes any string and converts it into a decimal string
export function stringToDecimal(value: string, preserveSign = true) {
  // if not a decimal then treat as an integer
  // allow negative 0 as it could become a non-zero value with more input. ie. -0 -> -0.1
  if (!IS_DECIMAL.test(value))
    return stringToInteger(value, { preserveSign, allowNegativeZero: true })

  // the strategy is to split into the left/right side of the decimal and treat each as an integer
  // use `= ''` to avoid dealing with undefined
  const [left = '', right = ''] = value.split(/\.(.*)/s)

  // left side can be negative but can't be empty (ignoring sign)
  // default to 0 if no numerals present
  const leftInteger = stringToInteger(left, {
    preserveSign,
    allowNegativeZero: preserveSign
  })
  const leftWithZero = ['', '-'].includes(leftInteger)
    ? `${leftInteger}0` // If no numerals on left side, prepend with 0 preserving sign
    : leftInteger

  // right side must be an integer (incl. empty) but can't be negative
  const rightInteger = stringToInteger(right, {
    preserveSign: false,
    dropLeadingZeros: false
  })

  // we know there must a `.` as !IS_DECIMAL has already returned early
  return `${leftWithZero}.${rightInteger}`
}
