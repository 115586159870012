import { InputLabel } from '@te-whatu-ora/anatomic'
import { useRendererModal } from '@healthnz-ult/fhir-engine-renderer'
import type { CommonInputProps } from '@healthnz-ult/fhir-engine-renderer/types'
import type { ComponentProps } from 'react'

export type IconType = ComponentProps<typeof InputLabel>['tertiaryLabelIcon']
export type PositionType = ComponentProps<
  typeof InputLabel
>['tertiaryLabelIconPosition']
export type LabelType = ComponentProps<typeof InputLabel>['tertiaryLabelAs']
export type OnClick = ComponentProps<typeof InputLabel>['onTertiaryLabelClick']

function useAdditionalLabelProperties({
  label,
  labelComponent,
  required,
  supportLink,
  supportModal
}: Partial<CommonInputProps<any>>) {
  const { openModal, modalExists } = useRendererModal()

  let tertiaryLabelProps

  if (supportLink) {
    tertiaryLabelProps = {
      href: supportLink,
      tertiaryLabelAs: 'a' as LabelType,
      tertiaryLabelIcon: 'link' as IconType,
      tertiaryLabelIconPosition: 'right' as PositionType
    }
  }

  if (supportModal && modalExists(supportModal)) {
    tertiaryLabelProps = {
      onTertiaryLabelClick: (() => openModal(supportModal)) as OnClick,
      tertiaryLabelIcon: 'link' as IconType,
      tertiaryLabelIconPosition: 'right' as PositionType
    }
  }

  return {
    ...tertiaryLabelProps,
    label: (labelComponent ?? label ?? '') as string,
    labelComponent,
    required
  }
}

export { useAdditionalLabelProperties }
