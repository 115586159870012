export class OpenModal extends HTMLElement {
  connectedCallback() {
    const modalId = this.getAttribute('linkId')

    this.setAttribute('role', 'link')
    this.setAttribute('tabindex', '0')
    this.style.cursor = 'pointer'
    this.style.textDecoration = 'underline'

    if (!modalId) return

    const contentText = this.textContent || 'Open modal'
    this.setAttribute('aria-label', contentText)

    this.addEventListener('click', () => {
      const event = new CustomEvent('open-modal', {
        detail: modalId
      })

      document.dispatchEvent(event)
    })

    this.addEventListener('keydown', e => {
      if (e.key === 'Enter' || e.key === ' ') {
        this.click()
        e.preventDefault()
      }
    })
  }
}

customElements.define('open-modal', OpenModal)
