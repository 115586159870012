/* eslint-disable react/destructuring-assignment */
import React, { ErrorInfo, ReactNode } from 'react'
import { CustomError } from 'lib/error'

import { GenericError } from './errors/GenericError'
import { DefaultError } from './errors/DefaultError'

interface Props {
  children?: ReactNode
  canQuit?: boolean
}

interface State {
  error: unknown
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { error: null }
  }

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { error }
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Maybe later log to a real service?
    // eslint-disable-next-line no-console
    console.error(error, errorInfo)
  }

  get hasError() {
    return this.state.error !== null && this.state.error !== undefined
  }

  render() {
    if (!this.hasError) return this.props.children

    const { error } = this.state

    // add more error types here if needed
    if (error instanceof CustomError)
      return <GenericError error={error} canQuit={this.props.canQuit} />

    return <DefaultError error={error} canQuit={this.props.canQuit} />
  }
}

// Higher-Order Component for Error Boundary
export function withErrorBoundary(
  Component: React.ComponentType<any>,
  canQuit?: boolean
) {
  return function ErrorBoundaryWrappedComponent(props: any) {
    return (
      <ErrorBoundary canQuit={canQuit}>
        <Component {...props} />
      </ErrorBoundary>
    )
  }
}
