import type { Reference } from '@healthnz-ult/fhir-engine-renderer/types'
import debounce from 'lodash.debounce'
import React from 'react'
import AsyncSelect from 'react-select/async'

import {
  Bundle,
  createLabelReferenceDropdown,
  createReferenceResult,
  Entry,
  InputReferenceProps
} from 'components/fhirEngine/referenceInput'
import customComponents from 'components/fhirEngine/select/components'

import 'components/fhirEngine/referenceInput/styles.css'

interface ReferenceMultiOption {
  label: string
  value: Reference
}

export default function InputReferenceList({
  handleSearch,
  inputId,
  onBlur,
  onAdd,
  referenceResource,
  required
}: InputReferenceProps) {
  async function loadOptions(
    inputValue: string,
    callback: (options: ReferenceMultiOption[]) => void
  ) {
    const result = (await handleSearch(inputValue)) as Bundle | undefined
    if (result?.entry && result?.entry.length > 0) {
      callback(
        result.entry.map((entry: Entry) => ({
          label: createLabelReferenceDropdown(entry.resource),
          value: createReferenceResult(entry.resource, referenceResource)
        }))
      )
    }
    callback([])
  }

  const debouncedLoadOptions = debounce(loadOptions, 500)

  function handleLoadOptions(
    inputValue: string,
    callback: (options: ReferenceMultiOption[]) => void
  ) {
    debouncedLoadOptions(inputValue, callback)
  }

  function handleChange(option: ReferenceMultiOption) {
    onAdd(option.value)
  }

  return (
    <AsyncSelect
      components={customComponents}
      cacheOptions
      id={inputId}
      isClearable={false}
      isMulti={false}
      loadOptions={handleLoadOptions}
      onBlur={onBlur}
      onChange={handleChange}
      required={required}
    />
  )
}
