import { ModalProps } from '@healthnz-ult/fhir-engine-renderer/types'
import { ControllableDialogRoot } from 'components/shared/controllableDialogRoot/ControllableDialogRoot'
import {
  IntersectionMeasurement,
  MeasureIntersection
} from 'components/shared/intersection'
import { ReactNode, useEffect, useState } from 'react'
import { Dialog } from '@te-whatu-ora/anatomic'

interface BaseModalProps extends Omit<ModalProps, 'formItems'> {
  children: ReactNode
}

export function BaseModal({ children, onClose, title }: BaseModalProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [measurement, setMeasurement] = useState<IntersectionMeasurement>()

  useEffect(() => {
    if (!isOpen) return
    if (!measurement) return

    // Anatomic doesn't pass a ref through to the modal so we have to rely on querying
    const modal = document.querySelector(
      "[role='dialog']._1iolpaq1"
    ) as HTMLDivElement
    if (!modal) return

    modal.style.top = `${measurement.offset + measurement.height / 2}px`
  }, [isOpen, measurement])

  return (
    <>
      <MeasureIntersection onMeasure={setMeasurement} />
      <ControllableDialogRoot
        modal
        heading={title}
        onOpen={() => setIsOpen(true)}
        onClose={onClose}
        trigger={
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <button
            data-testid='modal-trigger'
            id='modal-trigger'
            style={{ visibility: 'hidden' }}
            type='button'
          />
        }
      >
        <Dialog.Content>{children}</Dialog.Content>
      </ControllableDialogRoot>
    </>
  )
}
