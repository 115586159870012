import { Box, Button } from '@te-whatu-ora/anatomic'
import { Root, createRoot } from 'react-dom/client'

export class ExitButton extends HTMLElement {
  root: Root

  constructor() {
    super()

    this.root = createRoot(this)
  }

  connectedCallback() {
    const reason = this.getAttribute('reason') ?? 'quit'

    function handleClick() {
      window.parent.postMessage(
        { type: 'exit', reason },
        '*' // allowing any target domain because we don't send sensitive data
      )
    }

    this.root.render(
      <Box style={{ display: 'flex', justifyContent: 'center' }}>
        <Button onClick={handleClick}>{this.innerText}</Button>
      </Box>
    )
  }

  disconnectedCallback() {
    this.root.unmount()
  }
}

customElements.define('exit-button', ExitButton)
