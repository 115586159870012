import { Outlet } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import '@te-whatu-ora/anatomic/styles'
import {
  Badge,
  Box,
  Container,
  Header,
  ThemeProvider
} from '@te-whatu-ora/anatomic'
import { webSelfService } from '@te-whatu-ora/anatomic-themes'
import { appEnvironment } from 'env'
import { Show } from 'components/show'
import { WatermarkContainer } from 'components/watermarkContainer'

function Layout() {
  const showEnv = appEnvironment !== 'prod'
  const watermark = appEnvironment.toUpperCase()

  return (
    <>
      <Helmet>
        <html lang='en' />
        <title>Te Whatu Ora</title>
      </Helmet>
      <ThemeProvider theme={webSelfService.className}>
        <div
          style={{
            display: 'flex',
            minHeight: '100vh',
            flexDirection: 'column'
          }}
        >
          <Header.Root variant='light' style={{ flexGrow: '0' }}>
            <Header.Main>
              <Header.Left>
                <Header.TeWhatuOraLogo />
                <Show when={showEnv}>
                  <Badge variant='info'>{watermark}</Badge>
                </Show>
              </Header.Left>
            </Header.Main>
          </Header.Root>
          <Box backgroundColor='primary5' style={{ flexGrow: '1' }}>
            <WatermarkContainer>
              <Container paddingY='xxlarge' style={{ position: 'relative' }}>
                <Outlet />
              </Container>
            </WatermarkContainer>
          </Box>
        </div>
      </ThemeProvider>
    </>
  )
}

export default Layout
