import { MultiSectionDigitalClock } from '@mui/x-date-pickers/MultiSectionDigitalClock'
import moment, { Moment } from 'moment'
import { forwardRef, Ref } from 'react'
import { hour12to24, hour24toPeriod, InputTimeValue } from '../timeInput/utils'
import './timePicker.css'

export function toMoment({
  hour,
  minute,
  period
}: InputTimeValue): Moment | null {
  if (minute === undefined || minute === '') return null
  if (period === undefined || !['AM', 'PM'].includes(period)) return null

  const hour24 = hour12to24({ hour, period })
  if (hour24 === undefined) return null

  const parsedHour24 = Number.parseInt(hour24, 10)
  if (Number.isNaN(parsedHour24)) return null

  const parsedMinute = Number.parseInt(minute, 10)
  if (Number.isNaN(parsedMinute)) return null

  return moment({ hour: parsedHour24, minute: parsedMinute })
}

export function fromMoment(value: Moment | null) {
  if (value === null) return null

  const hour12 = value.hour() % 12

  return {
    hour: hour12 === 0 ? '12' : hour12.toString().padStart(2, '0'),
    minute: value.minute().toString().padStart(2, '0'),
    period: hour24toPeriod(value.hour().toString())
  }
}

export interface TimePickerProps {
  value: InputTimeValue
  onChange?: (newValue: InputTimeValue) => void
}

export const TimePicker = forwardRef(
  ({ onChange, value }: TimePickerProps, ref: Ref<any>) => {
    return (
      <div ref={ref}>
        <MultiSectionDigitalClock<Moment>
          minutesStep={1}
          sx={{ width: 'fit-content', marginInline: '0' }}
          value={toMoment(value)}
          onChange={v => {
            const newValue = fromMoment(v)
            if (newValue === null) return
            onChange?.(newValue)
          }}
        />
      </div>
    )
  }
)
