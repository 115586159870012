import { QuestionnaireResponse } from '@healthnz-ult/fhir-engine-renderer/types'
import { apiEndpointUrl, xApiKey } from 'env'
import { handleNetworkResponse } from 'lib/error'

interface SubmitQuestionnaireOptions {
  questionnaireId: string
  authToken: string
}

export async function submitQuestionnaire(
  response: QuestionnaireResponse,
  { questionnaireId, authToken }: SubmitQuestionnaireOptions
) {
  return fetch(`${apiEndpointUrl}/v1/questionnaire/${questionnaireId}`, {
    method: 'post',
    body: JSON.stringify(response),
    headers: {
      Authorization: `Bearer ${authToken}`,
      'Content-Type': 'application/json',
      'x-api-key': xApiKey
    }
  }).then(handleNetworkResponse)
}
