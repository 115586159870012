import {
  Button,
  Card,
  Heading,
  Icon,
  Stack,
  Text
} from '@te-whatu-ora/anatomic'
import { Show } from 'components/show'
import { usePartialSave } from 'hooks/usePartialSave'
import { useParams } from 'react-router-dom'

export interface ErrorTemplateProps {
  canRecover?: Boolean
  canQuit?: Boolean
  message?: string
  name?: string
}

export function ErrorTemplate({
  canRecover = true,
  canQuit,
  message,
  name = 'Something went wrong'
}: ErrorTemplateProps) {
  const { questionnaireId } = useParams()
  const { clearPartialSaveData } = usePartialSave(questionnaireId || '')

  function handleQuit() {
    clearPartialSaveData()
    window.parent.postMessage(
      {
        type: 'exit',
        reason: 'quit'
      },
      '*' // allowing any target domain because we don't send sensitive data
    )
  }

  return (
    <Card>
      <Stack space='xsmall'>
        <Icon icon='alert' color='error100' variant='decorativeIcons' />
        <Heading level='3'>{name}</Heading>
        <Show when={!!message}>
          <Text>{message}</Text>
        </Show>
        <Show when={canRecover}>
          <Button onPress={() => window.location.reload()}>
            Reload Questionnaire
          </Button>
        </Show>
        <Show when={canQuit}>
          <Button onPress={handleQuit}>Back</Button>
        </Show>
      </Stack>
    </Card>
  )
}
