import { ToggleButton, InputMessage } from '@te-whatu-ora/anatomic'
import type { BooleanInputProps } from '@healthnz-ult/fhir-engine-renderer/types'
import { InputFieldset } from '../inputFieldset'

export default function BooleanInput(props: BooleanInputProps) {
  const { value, disabled, helperText, inputId, onChange, error } = props

  function handleChange(newValue: string) {
    switch (newValue) {
      case 'yes':
        return onChange(true)
      case 'no':
        return onChange(false)
      default:
        return onChange(null)
    }
  }

  return (
    <InputFieldset {...props}>
      <ToggleButton
        disabled={disabled}
        defaultValue={
          (value === true && 'yes') || (value === false && 'no') || undefined
        }
        labelLeft='Yes'
        valueLeft='yes'
        labelRight='No'
        valueRight='no'
        onChange={handleChange}
      />
      <InputMessage
        errorMessage={error}
        errorMessageProps={{ id: inputId }}
        helperText={helperText}
      />
    </InputFieldset>
  )
}
