import { InputMessage } from '@te-whatu-ora/anatomic'

import { Document, Page } from 'react-pdf'

interface ImageRendererProps extends PdfRendererProps {
  fileName?: string
}

function ImageRenderer({ fileName, fileUrl }: ImageRendererProps) {
  return (
    <img
      alt={fileName ? `Selected image: ${fileName}` : 'Selected image'}
      style={{
        maxHeight: '600px',
        width: '100%',
        height: '100%',
        objectFit: 'contain'
      }}
      src={fileUrl}
      width='half'
    />
  )
}

interface PdfRendererProps {
  fileUrl?: string
}

function PdfRenderer({ fileUrl }: PdfRendererProps) {
  return (
    <Document error='Error loading PDF' file={fileUrl} loading='Loading PDF...'>
      <Page
        canvasBackground='transparent'
        pageNumber={1}
        renderAnnotationLayer={false}
        renderTextLayer={false}
        scale={0.5}
      />
    </Document>
  )
}

export interface FilePreviewProps {
  file: File | null
  isLoading: boolean
}

export function FilePreview({ file, isLoading }: FilePreviewProps) {
  if (isLoading) return <InputMessage helperText='Loading file' />

  // TODO: replace 'file' with specific file type accepted
  if (file === null) return <InputMessage helperText='No file selected' />

  const fileUrl = URL.createObjectURL(file)

  if (file.type.startsWith('image/')) {
    return <ImageRenderer fileName={file.name} fileUrl={fileUrl} />
  }

  if (file.type === 'application/pdf') {
    return <PdfRenderer fileUrl={fileUrl} />
  }

  // TODO: replace 'file' with specific file type accepted
  return <InputMessage helperText={`Selected file: ${file.name}`} />
}
