import { Dialog } from '@te-whatu-ora/anatomic'
import { useEffect, useState } from 'react'

export interface ControllableDialogRootProps
  extends React.ComponentProps<typeof Dialog.Root> {
  onOpen?: () => void
  onClose?: () => void
}

export function ControllableDialogRoot({
  onOpen,
  onClose,
  ...props
}: ControllableDialogRootProps) {
  const [hasOpened, setHasOpened] = useState(false)

  // Anatomic requires us to supply a button for the user to click to open the modal.
  // We have our own system to trigger a modal opening so we have to simulate the trigger being clicked
  useEffect(() => {
    // don't start polling if modal is already open
    if (hasOpened) return

    // poll for the trigger until the modal is opened. The trigger doesn't always have the click event attached when it's first rendered
    // so we may have to click it multiple times before the modal opens.
    const intervalId = setInterval(() => {
      const trigger = document.getElementById('modal-trigger')

      trigger?.click()
    }, 10)

    // once the modal is opened the component will rerender causing the timeout to be cleared
    return () => clearTimeout(intervalId)
  }, [hasOpened])

  function handleOpenChange(open: boolean) {
    if (open) {
      setHasOpened(true)
      onOpen?.()
    } else {
      onClose?.()
    }
  }

  return <Dialog.Root {...props} onOpenChange={handleOpenChange} />
}
