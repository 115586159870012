import { Questionnaire as QuestionnaireType } from '@healthnz-ult/fhir-engine-renderer/types'
import { useEffect, useState } from 'react'
import { getQuestionnaire } from 'lib/getQuestionnaire/getQuestionnaire'
import { Metadata, getMetadata } from 'lib/getMetadata/getMetadata'
import { createConsent } from 'lib/createConsent/createConsent'
import { QuestionnaireWrapperData } from 'components/questionnaireWrapper/QuestionnaireWrapper'

export interface UseQuestionnaireSessionOptions {
  questionnaireId: string
  authToken: string
}

type UseQuestionnaireSessionReturn =
  | {
      loading: true
      error: undefined
      maintenanceMode: undefined
      data: undefined
    }
  | {
      loading: false
      error: Error
      maintenanceMode: undefined
      data: undefined
    }
  | {
      loading: false
      error: undefined
      maintenanceMode: boolean
      data: QuestionnaireWrapperData
    }

export function useQuestionnaireSession({
  questionnaireId,
  authToken
}: UseQuestionnaireSessionOptions): UseQuestionnaireSessionReturn {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error>()

  const [metadata, setMetadata] = useState<Metadata>()
  const [questionnaire, setQuestionnaire] = useState<QuestionnaireType>()

  useEffect(() => {
    setLoading(true)

    const awaitMetadata = getMetadata(questionnaireId)
    const awaitQuestionnaire = getQuestionnaire(questionnaireId, authToken)
    const awaitConsent = createConsent(questionnaireId, authToken)

    Promise.all([awaitMetadata, awaitQuestionnaire, awaitConsent])
      .then(([awaitedMetadata, awaitedQuestionnaire]) => {
        setMetadata(awaitedMetadata)
        setQuestionnaire(awaitedQuestionnaire)
      })
      .catch(setError)
      .finally(() => setLoading(false))
  }, [questionnaireId, authToken])

  if (loading) {
    return {
      loading,
      error: undefined,
      data: undefined,
      maintenanceMode: undefined
    }
  }

  if (error) {
    return {
      loading,
      error,
      data: undefined,
      maintenanceMode: undefined
    }
  }

  if (!questionnaire) {
    return {
      loading,
      error: new Error('Could not load questionnaire'),
      data: undefined,
      maintenanceMode: undefined
    }
  }

  return {
    data: {
      questionnaire,
      questionnaireId,
      authToken
    },
    maintenanceMode: metadata?.maintenanceMode === 'true',
    loading,
    error
  }
}
