import { CustomError as CustomErrorType } from 'lib/error'
import { ErrorTemplate } from './Template'

export interface CustomErrorProps {
  error: CustomErrorType
  canQuit?: boolean
}

export function GenericError({ error, canQuit }: CustomErrorProps) {
  return (
    <ErrorTemplate
      message={error.message}
      name={error.name}
      canRecover={error.canRecover}
      canQuit={canQuit}
    />
  )
}
