import {
  Box,
  Card,
  Column,
  Heading,
  Row,
  Stack,
  TextLinkButton
} from '@te-whatu-ora/anatomic'
import { Show } from 'components/show'
import type { PageLayoutProps } from '@healthnz-ult/fhir-engine-renderer/types'
import React, { Children } from 'react'
import { hasExitButtonCallback } from 'components/embeddedComponents/pageLayout/utils'
import { BackExitButtons } from 'components/embeddedComponents/pageLayout/backExitButtons'
import { ModalManager } from 'components/modalManager/ModalManager'
import { ContentLoader, NextSubmitButtons } from 'components/shared'
import { useSubmitState } from 'contexts/submitState/submitState'
import { usePartialSave } from 'hooks/usePartialSave'
import { useParams } from 'react-router-dom'
import { onPageChange } from '../utils'

export default function PageLayout({
  children,
  isPartialSaveLoading,
  onPrev,
  onNext,
  onSubmit,
  submitButtonLabel,
  title,
  validate
}: PageLayoutProps) {
  const { questionnaireId } = useParams()
  const { clearPartialSaveData } = usePartialSave(questionnaireId || '')

  const { submitState } = useSubmitState()

  const hasExitButton = hasExitButtonCallback()

  function onExit(reason: string) {
    clearPartialSaveData()
    window.parent.postMessage(
      {
        type: 'exit',
        reason
      },
      '*' // allowing any target domain because we don't send sensitive data
    )
  }

  const handleNext = onNext
    ? () => {
        onNext()
        onPageChange()
      }
    : undefined

  const handlePrevious = onPrev
    ? () => {
        onPrev()
        onPageChange()
      }
    : undefined

  return (
    <>
      <ModalManager />
      <Row>
        <Column columns={12}>
          <Stack space='medium'>
            <BackExitButtons
              hasExitButton={hasExitButton}
              onExit={() => onExit('back')}
              onPrev={handlePrevious}
              submitState={submitState}
            />
            <Show when={title}>
              <Heading level='3' color='primary100' as='h1'>
                {title}
              </Heading>
            </Show>
            <ContentLoader state={submitState} loadingMessage='Submitting'>
              {Children.map(children, (item, itemIndex) => (
                <Card key={itemIndex}>
                  {item}
                  <Show when={itemIndex === Children.count(children) - 1}>
                    <NextSubmitButtons
                      hasSubmit={!!onSubmit}
                      isPartialSaveLoading={isPartialSaveLoading}
                      onNext={handleNext}
                      submitButtonLabel={submitButtonLabel}
                      submitState={submitState}
                      validate={validate}
                    />
                    <Show when={hasExitButton && submitState !== 'success'}>
                      <Box
                        style={{ display: 'flex', justifyContent: 'center' }}
                        paddingTop='small'
                      >
                        <TextLinkButton onPress={() => onExit('quit')}>
                          Quit
                        </TextLinkButton>
                      </Box>
                    </Show>
                  </Show>
                </Card>
              ))}
            </ContentLoader>
          </Stack>
        </Column>
      </Row>
    </>
  )
}
