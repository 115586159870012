import { Show } from 'components/show'
import { appEnvironment } from 'env'

export interface WatermarkContainerProps {
  children: React.ReactNode
}

export default function WatermarkContainer({
  children
}: WatermarkContainerProps) {
  const showEnv = appEnvironment !== 'prod'
  const watermark = appEnvironment.toUpperCase()

  return (
    <div style={{ position: 'relative' }}>
      <Show when={showEnv}>
        <div className='watermark-container'>
          <div className='watermark'>{watermark}</div>
          <div className='watermark'>{watermark}</div>
          <div className='watermark'>{watermark}</div>
        </div>
      </Show>
      <div style={{ position: 'relative' }}>{children}</div>
    </div>
  )
}
