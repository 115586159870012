import { InputFieldStyles, InputText, useText } from '@te-whatu-ora/anatomic'
import { forwardRef, useState } from 'react'
import type {
  Decimal,
  DecimalInputProps
} from '@healthnz-ult/fhir-engine-renderer/types'
import { useAdditionalLabelProperties } from 'components/fhirEngine/utils/additionalProperties'
import { RequiredInputWrapper } from 'components/fhirEngine/utils/requiredLabel'
import { stringToDecimal } from '../utils/numberUtils'

export interface InputDecimalProps
  extends Omit<
    React.ComponentProps<typeof InputText>,
    'label' | 'type' | 'value' | 'onChange' | 'inputMode'
  > {
  id: string
  label?: string
  labelComponent?: React.ReactNode
  value?: string
  onChange?: (value: string) => void
  allowNegative?: boolean
  hideBorder?: boolean
}

export function UnstyledTextInput({
  name,
  ref,
  ...rest
}: Omit<React.ComponentProps<typeof InputText>, 'label'>) {
  const textSizeClasses = useText({
    size: 'medium',
    weight: 'regular'
  })

  return (
    <input
      {...rest}
      className={`unstyled-input ${InputFieldStyles.inputBase} ${textSizeClasses}`}
      id={name}
    />
  )
}

// An Anatomic-style input for decimal values
export const InputDecimal = forwardRef<HTMLInputElement, InputDecimalProps>(
  (
    {
      value,
      id,
      hideBorder = false,
      label,
      onChange,
      allowNegative = true,
      labelComponent,
      required,
      ...rest
    },
    ref
  ) => {
    const [privateValue, setPrivateValue] = useState(value?.toString() ?? '')

    const additionalProps = useAdditionalLabelProperties({
      label,
      labelComponent,
      required
    })

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      const decimal = stringToDecimal(event.target.value, allowNegative)

      setPrivateValue(decimal)

      // only call onChange if actual value changed
      if (privateValue !== decimal) {
        onChange?.(decimal)
      }
    }

    if (hideBorder) {
      return (
        <UnstyledTextInput
          {...rest}
          type='text'
          value={privateValue}
          onChange={handleChange}
          inputMode='decimal'
          ref={ref}
        />
      )
    }

    return (
      <RequiredInputWrapper
        inputId={id}
        labelComponent={labelComponent}
        required={required}
      >
        <InputText
          {...rest}
          {...additionalProps}
          type='text'
          value={privateValue}
          onChange={handleChange}
          inputMode='decimal'
          ref={ref}
        />
      </RequiredInputWrapper>
    )
  }
)

// Wrap the Anatomic-style InputDecimal in a FHIR adapter
export function DecimalInput(props: DecimalInputProps) {
  const {
    error,
    helperText,
    inputId,
    onBlur,
    onChange,
    onFocus,
    required,
    value
  } = props

  function handleChange(newValue: string) {
    const parsedNewValue = newValue === '' ? null : Number.parseFloat(newValue)
    onChange(parsedNewValue as Decimal)
  }

  // We need to convert from `Number | null` to `string | undefined` and gracefully handle NaN and infinity
  const inputValue =
    value === null || !Number.isFinite(value) ? undefined : value.toString()

  const additionalProps = useAdditionalLabelProperties(props)

  return (
    <InputDecimal
      {...additionalProps}
      id={inputId}
      errorMessage={error}
      helperText={helperText}
      name={inputId}
      onBlur={onBlur}
      onChange={handleChange}
      onFocus={onFocus}
      required={required}
      value={inputValue}
    />
  )
}
