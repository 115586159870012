export function getIntegerParam(params: URLSearchParams, paramName: string) {
  const rawParam = params.get(paramName)

  if (rawParam === '' || rawParam === null) return

  const parsedParam = Number.parseInt(rawParam, 10)
  if (!Number.isInteger(parsedParam)) return

  return parsedParam
}

export function getContainerCss(src?: string) {
  const { searchParams } = new URL(src ?? document.location.href)

  const breakpointQueries = searchParams
    .get('breakpoints')
    ?.split(',')
    .map(def => {
      const [name, size] = def.split(':')
      const parsedSize = Number.parseInt(size, 10)

      if (!Number.isInteger(parsedSize)) return

      const mediaQuery = [`@media (width > ${parsedSize}px) {`]

      const columnWidth = getIntegerParam(searchParams, `${name}:width`)
      if (columnWidth) mediaQuery.push(`--column-width: ${columnWidth}px;`)

      const marginX = getIntegerParam(searchParams, `${name}:mx`)
      if (marginX) mediaQuery.push(`--margin-x: ${marginX}px;`)

      const marginTop = getIntegerParam(searchParams, `${name}:mt`)
      if (marginTop) mediaQuery.push(`--margin-top: ${marginTop}px;`)

      const marginBottom = getIntegerParam(searchParams, `${name}:mb`)
      if (marginBottom) mediaQuery.push(`--margin-bottom: ${marginBottom}px;`)

      mediaQuery.push(`}`)

      return mediaQuery.join(' ')
    })
    .filter(Boolean)
    .join('\n  ')

  const width = getIntegerParam(searchParams, 'width')
  const marginX = getIntegerParam(searchParams, 'mx')
  const marginTop = getIntegerParam(searchParams, 'mt')
  const marginBottom = getIntegerParam(searchParams, 'mb')

  return `.container {
  margin: auto;
  width: calc(100% - 2 * var(--margin-x));
  max-width: var(--column-width);
  margin-top: var(--margin-top);
  margin-bottom: var(--margin-bottom);
  
  --column-width: ${width ?? 1200}px;
  --margin-x: ${marginX ?? 16}px;
  --margin-top: ${marginTop ?? 24}px;
  --margin-bottom: ${marginBottom ?? 40}px;
  
  ${breakpointQueries ?? ''}
}`
}
