import { Box, Heading, Text } from '@te-whatu-ora/anatomic'
import { Divider } from 'components/accessibleDivider'

export default function MaintenancePage() {
  return (
    <>
      <Box paddingBottom='small'>
        <Heading level='2' as='h1' color='primary100'>
          Maintenance
        </Heading>
        <Heading level='4' color='primary100'>
          This service is currently unavailable.
        </Heading>
      </Box>
      <Box marginTop='small' marginBottom='medium'>
        <Divider variant='light' />
      </Box>
      <Box marginTop='small' marginBottom='medium'>
        <Text>
          The system is undergoing maintenance and is unavailable. Please come
          back later.
        </Text>
      </Box>
    </>
  )
}
