import {
  toDateString,
  toFullDateString,
  toInputDateValue
} from '../dateInput/utils'
import {
  hour24to12,
  hour24toPeriod,
  toTimeString,
  toTimeValue
} from '../timeInput/utils'

export interface DateTimeValue {
  day?: string
  month?: string
  year?: string

  hour?: string
  minute?: string
  period?: string
}

export function toDateTimeValue(
  value: string | null,
  cachedPeriod?: string | null
): DateTimeValue {
  if (value === null) return {}

  // If no time, then there is not timezone
  if (!value.includes('T')) return toInputDateValue(value)

  // Is a valid ISO8061 datetime string. It's easiest to assume everything is in the local timezone
  // so we convert this datetime to local and drop the timezone
  if (!Number.isNaN(Date.parse(value))) {
    const date = new Date(value)

    // all these methods (ie. getMonth, getMinutes, etc.) return the parts in the local zone
    return {
      year: date.getFullYear().toString().padStart(4, '0'),
      month: (date.getMonth() + 1).toString().padStart(2, '0'),
      day: date.getDate().toString().padStart(2, '0'),

      hour: hour24to12(date.getHours().toString()).padStart(2, '0'),
      minute: date.getMinutes().toString().padStart(2, '0'),
      period: hour24toPeriod(date.getHours().toString())?.padStart(2, '0')
    }
  }

  // We have a DateTime but it it's missing parts
  // We can assume it must be in the local timezone because of 2 things:
  // a datetime from a FHIR server would be complete
  // all dates we generated locally use the local timezone
  // therefore an incomplete date must be produced locally and so must be in local time
  const [date, time] = value.replace(/Z|((\+|-)[^:]*:[^:]*)$/, '').split('T') // remove timezone before splitting

  return {
    ...toInputDateValue(date),
    ...toTimeValue(time, cachedPeriod)
  }
}

function getTimezone({ year, month, day }: DateTimeValue) {
  const yearInt = Number.parseInt(year ?? '', 10)
  const monthInt = Number.parseInt(month ?? '', 10) - 1 // months are 0-indexed
  const dayInt = Number.parseInt(day ?? '', 10)

  // we can't calc a TZ without knowing the date. This is bc the local TZ changes throughout the year
  // ie. in NZ the summer TZ is +13:00 and the winter TZ is +12:00
  if (Number.isNaN(yearInt) || Number.isNaN(monthInt) || Number.isNaN(dayInt))
    return ''
  const timezoneOffset = new Date(yearInt, monthInt, dayInt).getTimezoneOffset() // TZ offset in local time at the supplied date

  if (timezoneOffset === 0) return 'Z'

  const offsetSign = timezoneOffset >= 0 ? '-' : '+' // the sign is flipped - ie. an offset of -180 is a timezone of +03:00
  const offsetHours = Math.abs(Math.floor(timezoneOffset / 60))
    .toString()
    .padStart(2, '0')
  const offsetMinutes = Math.abs(timezoneOffset % 60)
    .toString()
    .padStart(2, '0')

  return `${offsetSign}${offsetHours}:${offsetMinutes}`
}

export function toDateTimeString(value: DateTimeValue): string | null {
  const { year, month, day, hour, minute, period } = value

  // if no parts, return null
  if (
    (year === undefined || year === '') &&
    (month === undefined || month === '') &&
    (day === undefined || day === '') &&
    (hour === undefined || hour === '') &&
    (minute === undefined || minute === '') &&
    (period === undefined || period === '')
  )
    return null

  // If no time parts, return Date or Partial Date (YYYY, YYYY-MM, or YYYY-MM-DD)
  if (
    (hour === undefined || hour === '') &&
    (minute === undefined || minute === '')
  )
    return toDateString(value)

  // If there is a time, we want a full date, not a partial date so we include the timezone
  return `${toFullDateString(value)}T${toTimeString(value)}${getTimezone(
    value
  )}`
}
