import { Questionnaire } from '@healthnz-ult/fhir-engine-renderer/types'
import { apiEndpointUrl, xApiKey } from 'env'
import {
  CustomError,
  GenericError,
  NotFoundError,
  handleNetworkResponse
} from 'lib/error'

export function getQuestionnaire(
  questionnaireId: string,
  authToken: string
): Promise<Questionnaire> {
  return fetch(`${apiEndpointUrl}/v1/questionnaire/${questionnaireId}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'x-api-key': xApiKey
    }
  })
    .then(handleNetworkResponse)
    .then(res => res?.json())
    .then(bundle => {
      const resource = bundle.entry?.[0]?.resource as Questionnaire | undefined

      if (!resource) {
        throw new NotFoundError()
      }

      return resource
    })
    .catch((e: unknown) => {
      if (e instanceof CustomError) throw e
      throw new GenericError()
    })
}
