import {
  Checkbox,
  CheckboxGroup,
  Box,
  InputMessage,
  InputLabel as InputLabelAnatomic,
  Text,
  Icon,
  InputFieldStyles,
  InputText,
  TextLinkButton
} from '@te-whatu-ora/anatomic'
import type { OpenCheckboxGroupProps } from '@healthnz-ult/fhir-engine-renderer/types'
import { InputLabel } from 'components/fhirEngine/inputLabel/InputLabel'
import { ChangeEvent, useEffect, useState } from 'react'

interface OpenValueProps {
  onClear: () => void
  value: string
}

function OpenValue({ onClear, value }: OpenValueProps) {
  return (
    <Box alignItems='center' display='flex' style={{ marginBottom: '20px' }}>
      <Icon
        icon='cross'
        variant='tagIcon'
        style={{ width: '26px', cursor: 'pointer' }}
        marginRight='small'
        onClick={onClear}
        aria-label={`Remove open answer: "${value}"`}
        flexShrink={0}
      />
      <Text
        aria-label={`Open answer: "${value}"`}
        style={{ wordBreak: 'break-word' }}
      >
        {value}
      </Text>
    </Box>
  )
}

interface OpenInputProps {
  onAdd: (value: string) => boolean
  error: string | undefined
}

function OpenInput({ onAdd, error }: OpenInputProps) {
  const [privateValue, setPrivateValue] = useState('')

  function handleOnAdd() {
    const success = onAdd(privateValue)

    if (success) {
      setPrivateValue('')
    }
  }

  return (
    <Box className={InputFieldStyles.field}>
      <InputText
        type='text'
        label=''
        name=''
        error={!!error}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setPrivateValue(e.target.value)
        }
        value={privateValue}
        aria-label='Open answer'
      />
      <Box className={InputFieldStyles.clearButtonBase} right='xsmall'>
        <TextLinkButton
          type='button'
          onPress={handleOnAdd}
          aria-label={`Add open-answer: "${privateValue}"`}
          icon='plus'
        />
      </Box>
    </Box>
  )
}

export default function OpenCheckboxGroup(props: OpenCheckboxGroupProps) {
  const {
    error,
    helperText,
    inputId,
    onAdd,
    onRemove,
    onRemoveByIndex,
    openLabel = 'Other',
    options,
    required,
    value
  } = props

  const [privateError, setPrivateError] = useState<string>()

  useEffect(() => {
    if (privateError) {
      const timeout = setTimeout(() => setPrivateError(undefined), 3000)

      return () => clearTimeout(timeout)
    }
  }, [privateError])

  const handleOptionChangeFor = (optionValue: number) => (checked: boolean) => {
    if (checked) {
      onAdd(optionValue)
    } else {
      onRemove(optionValue)
    }
  }

  function handleOnClearFor(index: number) {
    return () => onRemoveByIndex(index)
  }

  function handleAddOpen(valueToAdd: string) {
    if (valueToAdd === '') {
      setPrivateError("Can't add an empty answer")
      return false
    }

    if (value?.includes(valueToAdd)) {
      setPrivateError(`${valueToAdd} is already selected`)
      return false
    }

    onAdd(valueToAdd)
    return true
  }

  return (
    <Box>
      <InputLabel {...props} />
      <CheckboxGroup label='' aria-invalid={!!error} aria-required={required}>
        {options.map(option => (
          <Checkbox
            checked={value?.includes(option.value) ?? false}
            id={`${inputId}-${option.value}`}
            key={option.value}
            label={option.label}
            onCheckedChange={handleOptionChangeFor(option.value)}
          />
        ))}
      </CheckboxGroup>
      <InputLabelAnatomic label={openLabel} />
      {value?.map((v, index) => {
        if (typeof v === 'number') return null
        return (
          <OpenValue key={index} value={v} onClear={handleOnClearFor(index)} />
        )
      })}
      <OpenInput onAdd={handleAddOpen} error={privateError} />
      <InputMessage
        errorMessage={privateError || error}
        helperText={helperText}
      />
    </Box>
  )
}
