import { Box, BoxProps, Heading, Icon } from '@te-whatu-ora/anatomic'
import {
  RepeatedItemContainerProps,
  isGroupItem
} from '@healthnz-ult/fhir-engine-renderer/types'

import './styles.css'
import { Show } from 'components/show'
import { Divider } from 'components/accessibleDivider'

interface RemoveButtonProps extends BoxProps {
  disabled?: boolean
  onPress: () => void
}

function RemoveButton({ disabled, onPress, ...boxProps }: RemoveButtonProps) {
  return (
    <Box {...boxProps}>
      <button
        aria-label='remove answer'
        className='repeatable-item__remove-button'
        disabled={disabled}
        onClick={onPress}
        type='button'
      >
        <Icon icon='cross' variant='functionalIcons' />
      </button>
    </Box>
  )
}

export default function RepeatedItemContainer({
  canRemove,
  count,
  item,
  onRemove,
  RenderItem
}: RepeatedItemContainerProps<any>) {
  // we've chosen not to wrap Group Items in <Card>s inside the <GroupInput> to allow for
  // nesting the next button inside the final Group's <Card> in the <PageLayout>
  // this requires us to wrap Group Items everywhere else they're used
  if (isGroupItem(item)) {
    return (
      <>
        <Show when={item.text}>
          <Box display='flex' justifyContent='spaceBetween' alignItems='center'>
            <Heading level='4' color='primary100'>
              {item.text}
            </Heading>
            <RemoveButton
              disabled={!canRemove || count <= 1}
              marginLeft='xsmall'
              onPress={onRemove}
            />
          </Box>
          <Box marginTop='small' marginBottom='medium'>
            <Divider variant='light' />
          </Box>
        </Show>
        <RenderItem item={item} hideLabel hideHelperText />
      </>
    )
  }

  return (
    <Box key={item.linkId} display='flex' width='full' alignItems='center'>
      <div className='repeatable-item__component'>
        <RenderItem item={item} hideLabel hideHelperText />
      </div>
      <RemoveButton
        disabled={!canRemove || count <= 1}
        marginLeft='xsmall'
        onPress={onRemove}
      />
    </Box>
  )
}
