import { Box, Text } from '@te-whatu-ora/anatomic'
import type { DisplayInputProps } from '@healthnz-ult/fhir-engine-renderer/types'

export default function DisplayInput({
  category,
  label,
  labelComponent
}: DisplayInputProps) {
  if (category === 'security') {
    return (
      <Box backgroundColor='caution5' borderRadius='inputs' padding='small'>
        <Text as='div'>{labelComponent ?? label}</Text>
      </Box>
    )
  }

  if (category === 'help') {
    return (
      <Box backgroundColor='info5' borderRadius='inputs' padding='small'>
        <Text as='div' color='primary100'>
          {labelComponent ?? label}
        </Text>
      </Box>
    )
  }

  return <Text as='div'>{labelComponent ?? label}</Text>
}
