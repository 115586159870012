import { InputText } from '@te-whatu-ora/anatomic'
import { useRef, useState } from 'react'
import { Show } from 'components/show'
import { PartialSpinButton } from '../partialInput/PartialSpinButton'
import { InputTimeValue } from './utils'
import { PartialInputField, PartialSeparator } from '../partialInput'
import { selectInput } from '../partialInput/PartialInputField'
import { TimePicker } from '../timePicker/TimePicker'

export interface InputTimeProps
  extends Pick<
    React.ComponentProps<typeof InputText>,
    'disabled' | 'errorMessage' | 'helperText' | 'name' | 'required' | 'error'
  > {
  label?: string
  labelComponent?: React.ReactNode
  value: InputTimeValue
  onBlur?: () => void
  onChange?: (value: InputTimeValue) => void
}

export const hourProps = {
  'aria-label': 'The hour as a number between 1 and 12',
  max: 12,
  maxLength: 2,
  min: 1,
  pattern: '([0-9])|(0[1-9])|(1[0-2])',
  placeholder: 'HH'
}

export const minuteProps = {
  'aria-label': 'The minutes as a number between 1 and 31',
  max: 59,
  maxLength: 2,
  min: 1,
  pattern: '([0-9])|(0[1-9])|([1-5][0-9])',
  placeholder: 'MM'
}

export function filterPeriodValue(newValue: string) {
  const periodKey = newValue
    .toUpperCase()
    .replaceAll(/[^APM]/g, '') // remove non APM chars
    .replace(/^M*/, '')[0] // remove leading Ms

  if (periodKey === 'A') return 'AM'
  if (periodKey === 'P') return 'PM'
  return ''
}

export function periodFromOrdinal(ordinal: number | undefined) {
  if (ordinal === undefined) return undefined
  return ordinal % 2 === 0 ? 'AM' : 'PM'
}

export function periodToOrdinal(value: string | undefined) {
  if (value === undefined) return undefined
  if (value[0] === 'A') return 0
  if (value[0] === 'P') return 1
  return undefined
}

export const periodProps: Partial<
  React.ComponentProps<typeof PartialSpinButton>
> = {
  'aria-label': 'Is the time AM or PM',
  filterValue: filterPeriodValue,
  fromOrdinal: periodFromOrdinal,
  inputMode: 'text',
  max: 1,
  maxLength: 2,
  min: 0,
  onKeyDown: event => {
    // eslint-disable-next-line no-param-reassign
    if (event.key === 'Backspace') event.currentTarget.value = ''
  },
  pattern: '(AM)|(PM)',
  placeholder: '––',
  toOrdinal: periodToOrdinal
}

// An Anatomic-style input for date values
// Anatomic provides a Date input but it:
//  - doesn't expose a working onBlur
//  - uses type='number' not type='string'
//  - has some weird layout issues when using backticks
//  - doesn't handle all user input gracefully (e, backticks, -, 4 digit months, etc.)
//  - bad inputs aren't captured in an onChange
export function InputTime({
  disabled,
  errorMessage,
  helperText,
  label,
  labelComponent,
  name,
  required,
  value,
  onBlur,
  onChange
}: InputTimeProps) {
  const [pickerIsOpen, setPickerIsOpen] = useState(false)

  const { hour, minute, period } = value

  const hourRef = useRef<HTMLInputElement>(null)
  const minuteRef = useRef<HTMLInputElement>(null)
  const periodRef = useRef<HTMLInputElement>(null)

  function handleChangeFor(part: keyof InputTimeValue) {
    return function handleChange(newValue: string) {
      onChange?.({
        ...value,
        [part]: newValue
      })
    }
  }

  return (
    <>
      <PartialInputField
        childRefs={[hourRef, minuteRef, periodRef]}
        label={label}
        labelComponent={labelComponent}
        disabled={disabled}
        errorMessage={errorMessage}
        helperText={helperText}
        icon='pending'
        iconOnClick={() => setPickerIsOpen(v => !v)}
        onBlur={onBlur}
        required={required}
      >
        <PartialSpinButton
          {...hourProps}
          id={`${name}-hour`}
          onChange={handleChangeFor('hour')}
          ref={hourRef}
          selectNext={selectInput(minuteRef)}
          value={hour}
        />
        <PartialSeparator separator=' : ' />
        <PartialSpinButton
          {...minuteProps}
          id={`${name}-minute`}
          onChange={handleChangeFor('minute')}
          ref={minuteRef}
          selectPrevious={selectInput(hourRef, true)}
          selectNext={selectInput(periodRef)}
          value={minute}
        />
        <PartialSeparator separator=' ' />
        <PartialSpinButton
          {...periodProps}
          id={`${name}-period`}
          onChange={handleChangeFor('period')}
          ref={periodRef}
          selectPrevious={selectInput(minuteRef, true)}
          value={period}
        />
      </PartialInputField>
      <Show when={pickerIsOpen}>
        <div className='picker-container'>
          <TimePicker value={value} onChange={v => onChange?.(v)} />
        </div>
      </Show>
    </>
  )
}
