import { Box, InputFieldStyles, Text } from '@te-whatu-ora/anatomic'
import { Decimal } from '@healthnz-ult/fhir-engine-renderer/types'
import { InputDecimal } from '../decimalInput'
import { InputQuantityProps } from './types'
import '../partialInput/styles.css'

export default function SingleQuantityInput({
  disabled,
  inputId,
  onChange,
  options,
  required,
  value
}: InputQuantityProps) {
  function handleChange(newValue: string) {
    const parsedValue = Number.parseFloat(newValue)

    if (
      Number.isNaN(parsedValue) ||
      !Number.isFinite(parsedValue) ||
      !options?.[0]
    ) {
      return onChange(null)
    }

    onChange({
      value: parsedValue as Decimal,
      system: options[0].system,
      code: options[0].code,
      unit: options[0].display
    })
  }

  return (
    <div
      className={`partialInput-container ${InputFieldStyles.inputBase}`}
      style={{ display: 'flex' }}
    >
      <div style={{ flexGrow: '1' }}>
        <InputDecimal
          id={inputId}
          disabled={disabled}
          hideBorder
          label=''
          name={`${inputId}-quantity`}
          onChange={handleChange}
          required={required}
          value={value?.value?.toString()}
        />
      </div>
      <Box display='flex' alignItems='center' height='full'>
        <Text>{options?.[0]?.display ?? options?.[0]?.code}</Text>
      </Box>
    </div>
  )
}
