import { useCallback, useMemo } from 'react'
import { PartialSave as PartialSaveData } from '@healthnz-ult/fhir-engine-renderer'

const DEFAULT_TTL = 24 * 60 * 60 * 1000

export interface PartialSave {
  clearPartialSaveData: () => void
  data: PartialSaveData | undefined
  setPartialSaveData: (data: PartialSaveData) => Promise<void>
}

function getWithExpiry(key: string): PartialSaveData | undefined {
  const itemStr = localStorage.getItem(key)

  if (!itemStr) return undefined

  const item = JSON.parse(itemStr)
  const now = new Date().getTime()

  if (now > item.expiry ?? 0) {
    localStorage.removeItem(key)
    return undefined
  }

  return JSON.parse(item.value)
}

function setWithExpiry(key: string, value: string, ttl = DEFAULT_TTL) {
  const item = {
    value,
    expiry: new Date().getTime() + ttl
  }

  localStorage.setItem(key, JSON.stringify(item))
}

export function usePartialSave(questionnaireId: string): PartialSave {
  const localStorageKey = `partialSaveData-${questionnaireId}`

  const partialSave = useMemo(
    () => getWithExpiry(localStorageKey),
    [localStorageKey]
  )

  const clearPartialSaveData = useCallback(() => {
    localStorage.removeItem(localStorageKey)
  }, [localStorageKey])

  const setPartialSaveData = useCallback(
    async (data: PartialSaveData) => {
      setWithExpiry(localStorageKey, JSON.stringify(data))
    },
    [localStorageKey]
  )

  return {
    clearPartialSaveData,
    data: partialSave,
    setPartialSaveData
  }
}
