import { useEffect } from 'react'
import { useRendererModal } from '@healthnz-ult/fhir-engine-renderer'

export function ModalManager(): React.ReactNode {
  const { openModal } = useRendererModal()

  useEffect(() => {
    const openModalEventHandler = (e: CustomEvent<string>) => {
      openModal(e.detail)
    }

    document.addEventListener('open-modal', openModalEventHandler)

    return () =>
      document.removeEventListener('open-modal', openModalEventHandler)
  }, [openModal])

  return null
}
