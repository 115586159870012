import { FocusEvent, useRef, useState } from 'react'
import { InputDate as AnatomicInputDate } from '@te-whatu-ora/anatomic'
import { Show } from 'components/show'
import {
  PartialInputField,
  PartialSeparator,
  PartialSpinButton
} from '../partialInput'
import { InputDateValue } from './utils'
import { selectInput } from '../partialInput/PartialInputField'
import { DatePicker } from '../datePicker/DatePicker'

export const dayProps = {
  'aria-label': 'The day of the month as a number between 1 and 31',
  max: 31,
  maxLength: 2,
  min: 1,
  pattern: '[0-9]{1,2}',
  placeholder: 'DD'
}

export const monthProps = {
  'aria-label': 'The month as a number between 1 and 12',
  max: 12,
  maxLength: 2,
  min: 1,
  pattern: '[0-9]{1,2}',
  placeholder: 'MM'
}

export const yearProps = {
  'aria-label': 'The year as a 4 digit number',
  maxLength: 4,
  pattern: '[0-9]{1,4}',
  placeholder: 'YYYY'
}

export interface InputDateProps
  extends Omit<
    React.ComponentProps<typeof AnatomicInputDate>,
    'label' | 'ref' | 'value'
  > {
  label?: string
  labelComponent?: React.ReactNode
  onBlur?: () => void
  value: InputDateValue
}

// An Anatomic-style input for date values
// Anatomic provides a Date input but it:
//  - doesn't expose a working onBlur
//  - uses type='number' not type='string'
//  - has some weird layout issues when using backticks
//  - doesn't handle all user input gracefully (e, backticks, -, 4 digit months, etc.)
//  - bad inputs aren't captured in an onChange
export function InputDate({
  disabled,
  errorMessage,
  helperText,
  label,
  labelComponent,
  name,
  required,
  value,
  onBlur,
  onChange
}: InputDateProps) {
  const [pickerIsOpen, setPickerIsOpen] = useState(false)

  const { day, month, year } = value

  const dayRef = useRef<HTMLInputElement>(null)
  const monthRef = useRef<HTMLInputElement>(null)
  const yearRef = useRef<HTMLInputElement>(null)
  const datePickerRef = useRef<HTMLInputElement>(null)

  // TODO: This is supposed to be handled in the PartialInputField. Investigate
  function handleOnBlur(event?: FocusEvent<HTMLDivElement>) {
    // Check if the relatedTarget (element gaining focus) is inside datePickerRef
    if (
      datePickerRef.current &&
      datePickerRef.current.contains(event?.relatedTarget as Node)
    ) {
      return
    }

    onBlur?.()
  }

  function handleChangeFor(part: keyof InputDateValue) {
    return function handleChange(newValue: string) {
      onChange?.({
        ...value,
        [part]: newValue
      })
    }
  }

  return (
    <>
      <PartialInputField
        childRefs={[dayRef, monthRef, yearRef]}
        disabled={disabled}
        errorMessage={errorMessage}
        helperText={helperText}
        // TODO: replace with calendar icon
        icon='pending'
        iconOnClick={() => setPickerIsOpen(v => !v)}
        label={label || ''}
        labelComponent={labelComponent}
        onBlur={handleOnBlur}
        required={required}
      >
        <PartialSpinButton
          {...dayProps}
          id={`${name}day`}
          onChange={handleChangeFor('day')}
          ref={dayRef}
          selectNext={selectInput(monthRef)}
          value={day}
        />
        <PartialSeparator separator=' / ' />
        <PartialSpinButton
          {...monthProps}
          id={`${name}-month`}
          onChange={handleChangeFor('month')}
          ref={monthRef}
          selectNext={selectInput(yearRef)}
          selectPrevious={selectInput(dayRef, true)}
          value={month}
        />
        <PartialSeparator separator=' / ' />
        <PartialSpinButton
          {...yearProps}
          id={`${name}-year`}
          onChange={handleChangeFor('year')}
          ref={yearRef}
          selectPrevious={selectInput(monthRef, true)}
          value={year}
        />
      </PartialInputField>
      <Show when={pickerIsOpen}>
        <div className='picker-container'>
          <DatePicker
            onChange={v => onChange?.(v)}
            ref={datePickerRef}
            value={value}
          />
        </div>
      </Show>
    </>
  )
}
