import { Icon } from '@te-whatu-ora/anatomic'
import React from 'react'
import { Root, createRoot } from 'react-dom/client'

type IconType = React.ComponentProps<typeof Icon>['icon']
type VariantType = React.ComponentProps<typeof Icon>['variant']
type ColorType = React.ComponentProps<typeof Icon>['color']
type DisplayType = React.ComponentProps<typeof Icon>['display']

export class AnatomicIcon extends HTMLElement {
  root: Root

  constructor() {
    super()

    this.root = createRoot(this)
  }

  connectedCallback() {
    const icon = this.getAttribute('icon') as IconType | undefined
    const variant = this.getAttribute('variant') as VariantType | undefined
    const color = this.getAttribute('color') as ColorType | undefined
    const display = this.getAttribute('display') as DisplayType | undefined

    if (!icon) return

    // render icon as a child of web component
    // don't use a shadow DOM bc CRA wont allow us to attach a stylesheet to the shadow DOM
    this.root.render(
      <div className='inline-block'>
        <Icon icon={icon} variant={variant} color={color} display={display} />
      </div>
    )
  }

  disconnectedCallback() {
    this.root.unmount()
  }
}

customElements.define('anatomic-icon', AnatomicIcon)
