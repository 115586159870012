import {
  FHIREngineRenderer,
  PartialSave
} from '@healthnz-ult/fhir-engine-renderer'
import {
  Questionnaire,
  QuestionnaireResponse
} from '@healthnz-ult/fhir-engine-renderer/types'
import * as defaultComponents from 'components/fhirEngine'
import { expandValueSet } from 'lib/expandValueSet'
import { submitQuestionnaire } from 'lib/getQuestionnaire/submitQuestionnaire'
import { usePartialSave } from 'hooks/usePartialSave'
import { GenericError } from 'lib/error'

export interface QuestionnaireWrapperData {
  questionnaire: Questionnaire
  questionnaireId: string
  authToken: string
}

export interface QuestionnaireWrapperProps {
  data: QuestionnaireWrapperData
  onError: (error: unknown) => void
  onPartialSave?: (partialSave: PartialSave) => void
  onSubmit?: (response: QuestionnaireResponse) => void
  Renderer?: typeof FHIREngineRenderer
  customComponents?: typeof defaultComponents
}

export function QuestionnaireWrapper({
  data,
  onError,
  onPartialSave,
  onSubmit,
  Renderer = FHIREngineRenderer,
  customComponents = defaultComponents
}: QuestionnaireWrapperProps) {
  const { questionnaire, questionnaireId, authToken } = data

  const {
    clearPartialSaveData,
    data: partialSaveData,
    setPartialSaveData
  } = usePartialSave(questionnaireId)

  const handleSubmit = (response: QuestionnaireResponse) => {
    return submitQuestionnaire(response, { questionnaireId, authToken })
      .then(() => {
        clearPartialSaveData()
        onSubmit?.(response)
      })
      .catch(() =>
        onError(
          new GenericError(
            'An unexpected error occurred and we were unable to submit your response.',
            false
          )
        )
      )
  }

  const handlePartialSave = (partialSave: PartialSave) => {
    return setPartialSaveData(partialSave)
      .then(() => onPartialSave?.(partialSave))
      .catch(onError)
  }

  return (
    <Renderer
      customComponents={customComponents}
      isPartialSaveLoading={false}
      onPartialSave={handlePartialSave}
      onSubmit={handleSubmit}
      partialSaveData={partialSaveData}
      questionnaireData={questionnaire}
      expandValueSet={expandValueSet(questionnaireId, authToken)}
    />
  )
}
