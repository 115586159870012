import { FocusEvent, MouseEvent, RefObject } from 'react'
import { Icon, InputFieldStyles } from '@te-whatu-ora/anatomic'
import { useAdditionalLabelProperties } from 'components/fhirEngine/utils/additionalProperties'
import { Show } from 'components/show'
import { focusNearestInput } from './utils'
import { InputFieldset } from '../inputFieldset'
import './styles.css'

export interface PartialInputFieldProps {
  children: React.ReactNode
  childRefs: RefObject<HTMLInputElement>[]
  disabled?: boolean
  errorMessage?: string
  helperText?: string
  icon?: React.ComponentProps<typeof Icon>['icon']
  iconOnClick?: () => void
  label?: string
  labelComponent?: React.ReactNode
  onBlur?: (event?: FocusEvent<HTMLDivElement>) => void
  required?: boolean
  supportLink?: string
}

export function selectInput(
  inputRef: RefObject<HTMLInputElement>,
  selectEnd = false
) {
  return function select() {
    if (!inputRef.current) return
    const caretPos = selectEnd ? inputRef.current.value.length : 0
    inputRef.current.setSelectionRange(caretPos, caretPos)
    inputRef.current.focus()
  }
}

export function PartialInputField(props: PartialInputFieldProps) {
  const {
    children,
    childRefs,
    disabled,
    errorMessage,
    helperText,
    icon,
    iconOnClick,
    onBlur
  } = props

  function handleBlur(event: FocusEvent<HTMLDivElement>) {
    if (event.currentTarget.contains(event.relatedTarget)) return
    onBlur?.(event)
  }

  // when clicking inside field, focus the nearest child input
  function handleClick(event: MouseEvent<HTMLDivElement>) {
    const elements = childRefs.map(ref => ref.current) as HTMLInputElement[]

    // we can't find closest input if our refs are null
    if (elements.find(element => element === null)) return

    focusNearestInput(event, ...elements)
  }

  const additionalProps = useAdditionalLabelProperties(props)

  return (
    <InputFieldset
      {...additionalProps}
      disabled={disabled}
      errorMessage={errorMessage}
      helperText={helperText}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        className={`partialInput-container ${InputFieldStyles.inputBase}`}
        onClick={handleClick}
        onBlur={handleBlur}
      >
        {children}
        <Show when={icon}>
          <div className={InputFieldStyles.clearButtonBase}>
            <Icon
              icon={icon as any}
              onClick={iconOnClick}
              variant='functionalIcons'
              tabIndex={0}
            />
          </div>
        </Show>
      </div>
    </InputFieldset>
  )
}
