import { useEffect, useRef } from 'react'

export interface IntersectionHeightProps {
  onMeasure: (measurement: number) => void
}

export function getHeight(event: IntersectionObserverEntry[]) {
  return event[0].intersectionRatio * event[0].boundingClientRect.height
}

export function IntersectionHeight({ onMeasure }: IntersectionHeightProps) {
  const containerRef = useRef<HTMLDivElement>(null)

  // mostly used when inside an iframe
  // we're using intersection observers to identify how much of the viewport the iframe covers and the relative offset
  useEffect(() => {
    if (containerRef.current === null) return
    const containerElement = containerRef.current

    const intersectionObserver = new IntersectionObserver(event => {
      onMeasure(getHeight(event))
    })

    intersectionObserver.observe(containerElement)
    return () => intersectionObserver.unobserve(containerElement)
  }, [containerRef, onMeasure])

  return <div style={{ position: 'fixed', inset: 0 }} ref={containerRef} />
}
