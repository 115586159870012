import { InputText } from '@te-whatu-ora/anatomic'
import React, { forwardRef, useState } from 'react'
import type {
  Integer,
  IntegerInputProps
} from '@healthnz-ult/fhir-engine-renderer/types'
import { useAdditionalLabelProperties } from 'components/fhirEngine/utils/additionalProperties'
import { RequiredInputWrapper } from 'components/fhirEngine/utils/requiredLabel'
import { stringToInteger } from '../utils/numberUtils'

export interface InputIntegerProps
  extends Omit<
    React.ComponentProps<typeof InputText>,
    'label' | 'type' | 'value' | 'onChange' | 'inputMode'
  > {
  id: string
  label?: string
  labelComponent?: React.ReactNode
  value?: string
  onChange?: (value: string) => void
  allowNegative?: boolean
  dropLeadingZeros?: boolean
  maxLength?: number
}

// An Anatomic-style input for integer values
export const InputInteger = forwardRef<HTMLInputElement, InputIntegerProps>(
  (props, ref) => {
    const {
      dropLeadingZeros = true,
      allowNegative = true,
      id,
      label,
      labelComponent,
      maxLength,
      value,
      onChange,
      required,
      ...rest
    } = props

    const [privateValue, setPrivateValue] = useState(value?.toString() ?? '')

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      let integer = stringToInteger(event.target.value, {
        dropLeadingZeros,
        preserveSign: allowNegative
      })

      // optionally cap length of user input
      if (maxLength !== undefined) {
        integer = integer.slice(0, maxLength)
      }

      setPrivateValue(integer)

      // only call onChange if actual value changed
      if (privateValue !== integer) {
        onChange?.(integer)
      }
    }

    const additionalProps = useAdditionalLabelProperties({
      label,
      labelComponent,
      required
    })

    return (
      <RequiredInputWrapper
        inputId={id}
        labelComponent={labelComponent}
        required={required}
      >
        <InputText
          {...rest}
          {...additionalProps}
          type='text'
          value={value ?? privateValue}
          onChange={handleChange}
          inputMode='numeric'
          ref={ref}
        />
      </RequiredInputWrapper>
    )
  }
)

// Wrap the Anatomic-style InputInteger in a FHIR adapter
export function IntegerInput(props: IntegerInputProps) {
  const {
    error,
    helperText,
    inputId,
    onBlur,
    onChange,
    onFocus,
    required,
    value
  } = props

  function handleChange(newValue: string) {
    const parsedNewValue =
      newValue === '' ? null : Number.parseInt(newValue, 10)
    onChange(parsedNewValue as Integer)
  }

  // We need to convert from `Number | null` to `string | undefined` and gracefully handle NaN and infinity
  const inputValue =
    value === null || !Number.isFinite(value) ? undefined : value.toString()

  const additionalProps = useAdditionalLabelProperties(props)

  return (
    <InputInteger
      {...additionalProps}
      errorMessage={error}
      id={inputId}
      helperText={helperText}
      name={inputId}
      onBlur={onBlur}
      onChange={handleChange}
      onFocus={onFocus}
      required={required}
      value={inputValue}
    />
  )
}
