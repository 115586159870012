import {
  Box,
  InputMessage,
  InputText,
  RadioButton,
  RadioGroup
} from '@te-whatu-ora/anatomic'
import { Show } from 'components/show'
import type { OpenRadioButtonGroupProps } from '@healthnz-ult/fhir-engine-renderer/types'
import { ChangeEvent } from 'react'
import { InputLabel } from 'components/fhirEngine/inputLabel/InputLabel'

function getRadioValue(v: string | number | null) {
  if (typeof v === 'string') return 'other'
  if (typeof v === 'number') return `index-${v}`
  return undefined
}

export default function OpenRadioButtonGroup(props: OpenRadioButtonGroupProps) {
  const {
    error,
    helperText,
    inputId,
    onChange,
    openLabel = 'Other',
    options,
    required,
    value
  } = props

  function handleChange(newValue: string) {
    if (newValue === 'other') {
      onChange('')
      // In future we may want to avoid showing errors when the text input is first opened
      // unTouch (setting isTouched to false) could be a way to achieve this
      // unTouch()

      return
    }

    if (!newValue.startsWith('index-')) return

    const parsedValue = Number.parseInt(newValue.slice(6), 10)

    onChange(parsedValue)
  }

  function handleOpenChange(event: ChangeEvent<HTMLInputElement>) {
    onChange(event.target.value)
  }

  return (
    <Box>
      <InputLabel {...props} />
      <RadioGroup
        aria-invalid={!!error}
        aria-required={required}
        helperText={helperText}
        label=''
        name={inputId}
        onChange={handleChange}
        value={getRadioValue(value)}
      >
        {[
          // using an array is janky but Anatomic wont let us wrap it in a fragment
          ...options.map(option => {
            return (
              <RadioButton
                id={`${inputId}-${option.value}`}
                key={option.value}
                label={option.label}
                value={`index-${option.value}`}
              />
            )
          }),
          <RadioButton
            id={`${inputId}-other`}
            key='other'
            label={openLabel}
            value='other'
          />
        ]}
      </RadioGroup>
      <Show when={typeof value === 'string'}>
        <InputText
          error={!!error}
          label=''
          name={`${inputId}-open`}
          onChange={handleOpenChange}
          type='text'
          value={value as string}
        />
      </Show>
      <InputMessage errorMessage={error} />
    </Box>
  )
}
