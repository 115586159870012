import { Box, Icon, TextLinkButton } from '@te-whatu-ora/anatomic'
import { Show } from 'components/show'
import React from 'react'

interface BackExitButtonsProps {
  hasExitButton: boolean
  onExit?: () => void
  onPrev?: () => void
  submitState: 'error' | 'success' | 'loading' | 'ready'
}

export function BackExitButtons({
  hasExitButton,
  onExit,
  onPrev,
  submitState
}: BackExitButtonsProps) {
  return (
    <Show when={['ready', 'invalid'].includes(submitState)}>
      <Show
        when={onPrev}
        fallback={
          <Show when={hasExitButton && onExit}>
            <Box>
              <TextLinkButton onPress={onExit}>
                <Box display='flex'>
                  <Icon
                    icon='arrow_left'
                    marginRight='xsmall'
                    variant='decorativeIcons'
                  />
                  Back
                </Box>
              </TextLinkButton>
            </Box>
          </Show>
        }
      >
        <Box>
          <TextLinkButton onPress={onPrev}>
            <Box display='flex'>
              <Icon
                icon='arrow_left'
                marginRight='xsmall'
                variant='decorativeIcons'
              />
              Previous step
            </Box>
          </TextLinkButton>
        </Box>
      </Show>
    </Show>
  )
}
