import { Box, InputMessage } from '@te-whatu-ora/anatomic'
import Select, { MultiValue } from 'react-select'
import { InputLabel } from 'components/fhirEngine/inputLabel/InputLabel'
import { MultiSelectProps } from '@healthnz-ult/fhir-engine-renderer/types'
import React from 'react'
import { Option } from './type'
import {
  ClearIndicator,
  Control,
  DropdownIndicator,
  MultiValue as MultiValueComponent
} from './components'

export default function MultiSelect(props: MultiSelectProps) {
  const {
    error,
    helperText,
    inputId,
    onBlur,
    onChange,
    onFocus,
    options,
    value
  } = props

  const valueAsOptions = value?.map(v => options[v]) ?? []

  function handleChange(newValue: MultiValue<Option>) {
    onChange(newValue.map(v => v.value))
  }

  return (
    <Box>
      <InputLabel {...props} />
      <Select
        components={{
          ClearIndicator,
          Control,
          DropdownIndicator,
          MultiValue: MultiValueComponent
        }}
        inputId={inputId}
        isClearable
        isMulti
        onBlur={onBlur}
        onChange={handleChange}
        onFocus={onFocus}
        options={options}
        required
        value={valueAsOptions}
      />
      <InputMessage errorMessage={error} helperText={helperText} />
    </Box>
  )
}
