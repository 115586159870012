import {
  ToggleButton,
  InputMessage,
  Box,
  InputText
} from '@te-whatu-ora/anatomic'
import type {
  InputOption,
  OpenToggleButtonInputProps
} from '@healthnz-ult/fhir-engine-renderer/types'
import { InputLabel } from 'components/fhirEngine/inputLabel/InputLabel'
import { ChangeEvent } from 'react'
import { Show } from 'components/show'

interface EnrichedOpenToggleButtonInputProps
  extends OpenToggleButtonInputProps {
  handleChange(val: string): void
}

function determineDefaultValue(
  value: string | number | null,
  options: InputOption[]
) {
  if (typeof value === 'number') return `index-${options[0].value}`

  if (typeof value === 'string' && value.trim().length > 0) return 'other'

  return undefined
}

function BooleanInput({
  disabled,
  handleChange,
  openLabel = 'Other',
  options,
  value
}: EnrichedOpenToggleButtonInputProps) {
  const defaultValue = determineDefaultValue(value, options)

  return (
    <ToggleButton
      disabled={disabled}
      defaultValue={defaultValue}
      labelLeft={options[0].label}
      valueLeft={`index-${options[0].value}`}
      labelRight={openLabel}
      valueRight='other'
      onChange={handleChange}
    />
  )
}

function ToggleInput(props: EnrichedOpenToggleButtonInputProps) {
  const { options } = props

  switch (options.length) {
    case 0:
      return <p>No options provided</p>
    case 1:
      return <BooleanInput {...props} />
    default:
      return <p>Too many options provided</p>
  }
}

export default function OpenToggleButtonInput(
  props: OpenToggleButtonInputProps
) {
  const { error, helperText, inputId, onChange, value } = props

  function handleChange(newValue: string) {
    if (newValue === 'other') {
      onChange('')
      return
    }

    if (!newValue.startsWith('index-')) return

    const parsedValue = Number.parseInt(newValue.slice(6), 10)

    onChange(parsedValue)
  }

  function handleOpenChange(event: ChangeEvent<HTMLInputElement>) {
    onChange(event.target.value)
  }

  return (
    <Box>
      <InputLabel {...props} />

      <ToggleInput {...props} handleChange={handleChange} />

      <Show when={typeof value === 'string'}>
        <Box marginTop='small'>
          <InputText
            error={!!error}
            label=''
            name={`${inputId}-open`}
            onChange={handleOpenChange}
            type='text'
            value={value as string}
          />
        </Box>
      </Show>

      <InputMessage errorMessage={error} helperText={helperText} />
    </Box>
  )
}
