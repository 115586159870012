import type { TimeInputProps } from '@healthnz-ult/fhir-engine-renderer/types'
import { useState } from 'react'
import { InputTime } from './InputTime'
import { InputTimeValue, toTimeString, toTimeValue } from './utils'

// Wrap the Anatomic-style InputInteger in a FHIR adapter
export default function TimeInput({
  disabled,
  error,
  inputId,
  label,
  labelComponent,
  onBlur,
  onChange,
  required,
  value: timeString,
  helperText
}: TimeInputProps) {
  // we cache period because the conversion toTimeString looses this precision
  const [period, setPeriod] = useState<string | undefined | null>(null)

  function handleChange(newValue: InputTimeValue) {
    setPeriod(newValue.period)
    onChange(toTimeString(newValue))
  }

  // We only display the required '*' if the label doesn't have a component, or it'll mess with the layout.
  // Include the '*' in the labelComponent.
  // TODO: FIX ANATOMIC TYPE
  return (
    <InputTime
      disabled={disabled}
      error={!!error}
      errorMessage={error}
      helperText={helperText}
      label={label}
      labelComponent={labelComponent}
      name={inputId}
      required={!labelComponent && required}
      value={toTimeValue(timeString, period)}
      onBlur={onBlur}
      onChange={handleChange}
    />
  )
}
