import { FocusEvent, useRef, useState } from 'react'
import { Box, InputDate } from '@te-whatu-ora/anatomic'
import { Show } from 'components/show'
import { PartialSpinButton } from '../partialInput/PartialSpinButton'
import { hourProps, minuteProps, periodProps } from '../timeInput/InputTime'
import { dayProps, monthProps, yearProps } from '../dateInput/InputDate'
import { PartialInputField, PartialSeparator } from '../partialInput'
import { selectInput } from '../partialInput/PartialInputField'
import { DateTimeValue } from './utils'
import { TimePicker } from '../timePicker/TimePicker'
import { DatePicker } from '../datePicker/DatePicker'

export interface InputDateTimeProps
  extends Omit<
    React.ComponentProps<typeof InputDate>,
    'label' | 'ref' | 'value' | 'onChange'
  > {
  label?: string
  labelComponent?: React.ReactNode
  onBlur?: () => void
  onChange: (value: DateTimeValue) => void
  supportLink?: string
  value: DateTimeValue
}

// An Anatomic-style input for date values
// Anatomic provides a Date input but it:
//  - doesn't expose a working onBlur
//  - uses type='number' not type='string'
//  - has some weird layout issues when using backticks
//  - doesn't handle all user input gracefully (e, backticks, -, 4 digit months, etc.)
//  - bad inputs aren't captured in an onChange
export function InputDateTime({
  disabled,
  errorMessage,
  helperText,
  label,
  labelComponent,
  name,
  required,
  value,
  onBlur,
  onChange,
  supportLink
}: InputDateTimeProps) {
  const [pickerIsOpen, setPickerIsOpen] = useState(false)
  // Use a private date time value, so we can fill in time and date independently of data validation and
  // display the values in the input
  const [privateDateTime, setPrivateDateTime] = useState<DateTimeValue>({
    ...value
  })

  const {
    hour: privateHour,
    minute: privateMinute,
    period: privatePeriod,
    year: privateYear,
    month: privateMonth,
    day: privateDay
  } = privateDateTime

  const dayRef = useRef<HTMLInputElement>(null)
  const monthRef = useRef<HTMLInputElement>(null)
  const yearRef = useRef<HTMLInputElement>(null)
  const hourRef = useRef<HTMLInputElement>(null)
  const minuteRef = useRef<HTMLInputElement>(null)
  const periodRef = useRef<HTMLInputElement>(null)
  const datePickerRef = useRef<HTMLInputElement>(null)
  const timePickerRef = useRef<HTMLInputElement>(null)

  // TODO: This is supposed to be handled in the PartialInputField. Investigate
  function handleOnBlur(event?: FocusEvent<HTMLDivElement>) {
    // Check if the relatedTarget (element gaining focus) is inside datePickerRef or timePickerRef
    if (
      datePickerRef.current &&
      datePickerRef.current.contains(event?.relatedTarget as Node)
    ) {
      return
    }
    if (
      timePickerRef.current &&
      timePickerRef.current.contains(event?.relatedTarget as Node)
    ) {
      return
    }

    onBlur?.()
  }

  function handleChangeFor(part: keyof DateTimeValue) {
    return function handleChange(newValue: string) {
      setPrivateDateTime({
        ...privateDateTime,
        [part]: newValue
      })
      onChange?.({
        ...privateDateTime,
        [part]: newValue
      })
    }
  }

  function handleDateTimeChange(newDateTimeValue: DateTimeValue) {
    setPrivateDateTime({ ...privateDateTime, ...newDateTimeValue })
    onChange?.({ ...privateDateTime, ...newDateTimeValue })
  }

  return (
    <>
      <PartialInputField
        childRefs={[dayRef, monthRef, yearRef, hourRef, minuteRef, periodRef]}
        label={label ?? ''}
        labelComponent={labelComponent}
        disabled={disabled}
        errorMessage={errorMessage}
        helperText={helperText}
        // TODO: replace with calendar icon
        icon='pending'
        iconOnClick={() => setPickerIsOpen(v => !v)}
        onBlur={handleOnBlur}
        required={required}
        supportLink={supportLink}
      >
        <PartialSpinButton
          {...dayProps}
          id={`${name}day`}
          onChange={handleChangeFor('day')}
          ref={dayRef}
          selectNext={selectInput(monthRef)}
          value={privateDay}
        />
        <PartialSeparator separator=' / ' />
        <PartialSpinButton
          {...monthProps}
          id={`${name}-month`}
          onChange={handleChangeFor('month')}
          ref={monthRef}
          selectNext={selectInput(yearRef)}
          selectPrevious={selectInput(dayRef, true)}
          value={privateMonth}
        />
        <PartialSeparator separator=' / ' />
        <PartialSpinButton
          {...yearProps}
          id={`${name}-year`}
          onChange={handleChangeFor('year')}
          ref={yearRef}
          selectPrevious={selectInput(monthRef, true)}
          selectNext={selectInput(hourRef)}
          value={privateYear}
        />
        <Box display='inlineBlock' marginLeft='large' />
        <PartialSpinButton
          {...hourProps}
          id={`${name}-hour`}
          onChange={handleChangeFor('hour')}
          ref={hourRef}
          selectPrevious={selectInput(yearRef, true)}
          selectNext={selectInput(minuteRef)}
          value={privateHour}
        />
        <PartialSeparator separator=' : ' />
        <PartialSpinButton
          {...minuteProps}
          id={`${name}-minute`}
          onChange={handleChangeFor('minute')}
          ref={minuteRef}
          selectPrevious={selectInput(hourRef, true)}
          selectNext={selectInput(periodRef)}
          value={privateMinute}
        />
        <PartialSeparator separator=' ' />
        <PartialSpinButton
          {...periodProps}
          id={`${name}-period`}
          onChange={handleChangeFor('period')}
          ref={periodRef}
          selectPrevious={selectInput(minuteRef, true)}
          value={privatePeriod}
        />
      </PartialInputField>
      <Show when={pickerIsOpen}>
        <div className='picker-container'>
          <DatePicker
            onChange={v => handleDateTimeChange(v)}
            ref={datePickerRef}
            value={privateDateTime}
          />
          <TimePicker
            onChange={v => handleDateTimeChange(v)}
            ref={timePickerRef}
            value={privateDateTime}
          />
        </div>
      </Show>
    </>
  )
}
