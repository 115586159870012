import type { Reference } from '@healthnz-ult/fhir-engine-renderer/types'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  Bundle,
  getReferenceUrl,
  InputReference,
  User,
  validateReferenceInputValue
} from 'components/fhirEngine/referenceInput'
import { xApiKey } from 'env'
import { handleNetworkResponse } from 'lib/error'
import { useAuth } from 'contexts/auth/auth'

export interface CommonInputProps {
  error: string | undefined
  handleRemove: (reference: Reference) => void
  inputId: string
  label: string | React.ReactNode | undefined
  onAdd: (reference: Reference) => void
  onBlur: () => void
  onFocus?: () => void
  referenceResource: string
  required: boolean
  value: Reference[] | null
}

export default function CommonInput(props: CommonInputProps) {
  const { error, referenceResource } = props

  const authToken = useAuth()
  const { questionnaireId } = useParams()

  const [privateError, setPrivateError] = useState<string>()
  const [queryLoading, setQueryLoading] = useState<boolean>()
  const [searchById, setSearchById] = useState<boolean>(true)

  useEffect(() => {
    if (privateError) {
      const timeout = setTimeout(() => setPrivateError(undefined), 3000)

      return () => clearTimeout(timeout)
    }
  }, [privateError])

  async function handleSearch(
    inputValue: string
  ): Promise<Bundle | User | undefined> {
    const inputError = validateReferenceInputValue(
      referenceResource,
      searchById,
      inputValue
    )
    if (inputError) {
      setPrivateError(inputError)
      return undefined
    }

    const url = getReferenceUrl({
      questionnaireId,
      referenceResource,
      searchById,
      value: inputValue
    })

    try {
      setQueryLoading(true)
      const res = await fetch(url, {
        method: 'get',
        headers: {
          Authorization: authToken,
          'x-api-key': xApiKey
        }
      })
      handleNetworkResponse(res)
      setQueryLoading(false)
      return await res?.json()
    } catch (fetchError) {
      setPrivateError('An error occurred while searching. Please try again.')
    }
    setQueryLoading(false)
  }

  return (
    <InputReference
      {...props}
      error={privateError || error}
      handleSearch={handleSearch}
      queryLoading={queryLoading}
      searchById={searchById}
      setPrivateError={setPrivateError}
      setSearchById={setSearchById}
    />
  )
}
