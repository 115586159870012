import { Box, InputFieldStyles, Badge, Icon } from '@te-whatu-ora/anatomic'
import {
  ClearIndicatorProps,
  ControlProps,
  DropdownIndicatorProps,
  MultiValueProps,
  ValueContainerProps,
  components,
  OptionProps
} from 'react-select'

export function Control({ children, innerProps }: ControlProps<any, any>) {
  return (
    <div className={InputFieldStyles.field}>
      <div
        className={InputFieldStyles.input.base}
        style={{
          display: 'flex',
          lineHeight: '1',
          height: 'unset',
          minHeight: '56px',
          paddingRight: '4px'
        }}
        {...innerProps}
      >
        {children}
      </div>
    </div>
  )
}

export function ValueContainer({ children }: ValueContainerProps<any, any>) {
  return <div>{children}</div>
}

export function MultiValue({
  children,
  removeProps
}: MultiValueProps<any, any>) {
  return (
    <Box style={{ margin: '0 4px' }}>
      <Badge variant='neutral'>
        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          {children}
          <div {...removeProps} style={{ cursor: 'pointer' }}>
            <Icon icon='cross' variant='tagIcon' />
          </div>
        </div>
      </Badge>
    </Box>
  )
}

export function ClearIndicator(props: ClearIndicatorProps<any, any>) {
  return (
    <components.ClearIndicator {...props}>
      <Icon icon='cross' variant='tagIcon' />
    </components.ClearIndicator>
  )
}

export function DropdownIndicator(props: DropdownIndicatorProps<any, any>) {
  return (
    <components.DropdownIndicator {...props}>
      <Icon icon='chevron_down' variant='tagIcon' />
    </components.DropdownIndicator>
  )
}

const Option = (props: OptionProps<any, any>) => {
  const { isSelected, innerProps } = props
  // To make options selectable with VoiceOver/TalkBack, We should avoid using tabindex="-1".
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { tabIndex: _, ...rest } = innerProps

  return (
    <components.Option
      {...props}
      innerProps={{
        ...rest,
        role: 'option',
        'aria-selected': isSelected
      }}
    />
  )
}

export default {
  ClearIndicator,
  Control,
  DropdownIndicator,
  MultiValue,
  Option
}
