import { Box, InputMessage } from '@te-whatu-ora/anatomic'
import { SingleValue } from 'react-select'
import Creatable from 'react-select/creatable'
import {
  CreatableSelectProps,
  InputOption
} from '@healthnz-ult/fhir-engine-renderer/types'
import { InputLabel } from 'components/fhirEngine/inputLabel/InputLabel'
import React from 'react'
import { CreatableOption } from './type'
import { ClearIndicator, Control, DropdownIndicator } from './components'

const valueToOptionFor =
  (options: InputOption[]) =>
  (value: string | number | null): CreatableOption | undefined => {
    if (value === null) return undefined

    if (typeof value === 'number') return options[value]

    return { value, label: value }
  }

export default function CreatableSelect(props: CreatableSelectProps) {
  const {
    error,
    helperText,
    inputId,
    onBlur,
    onChange,
    onFocus,
    options,
    value
  } = props

  function handleChange(newValue: SingleValue<CreatableOption>) {
    onChange(newValue?.value ?? null)
  }

  const valueOption = valueToOptionFor(options)(value)

  return (
    <Box>
      <InputLabel {...props} />
      <Creatable
        components={{
          ClearIndicator,
          Control,
          DropdownIndicator
        }}
        inputId={inputId}
        isClearable
        isMulti={false}
        onBlur={onBlur}
        onChange={handleChange}
        onFocus={onFocus}
        options={options}
        required
        value={valueOption}
      />
      <InputMessage errorMessage={error} helperText={helperText} />
    </Box>
  )
}
