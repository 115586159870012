import range from 'lodash.range'
import { useEffect, useRef } from 'react'

export interface IntersectionOffsetProps {
  sectionCount: number
  onMeasure: (measurement: number) => void
}

export function getOffset(event: IntersectionObserverEntry[]) {
  if (event.length === 0) return 0

  // height in px of the sections. Should be the same for all sections (bc flexbox) so we only check the first
  const sectionHeight = event[0].boundingClientRect.height

  // number of sections fully off screen and also the index of first section to appear fully or partially on screen
  const sectionsOffScreen = event.findIndex(e => e.intersectionRatio !== 0)

  // offset (distance between top of iframe and top of parent's viewport) in sections
  const offsetInSections =
    sectionsOffScreen + (1 - (event[sectionsOffScreen]?.intersectionRatio ?? 0)) // this array access should work so the `??` is just for defensiveness

  // convert from sections to px
  return offsetInSections * sectionHeight
}

// measures the offset between the top of the iframe and the top of its intersection with the parent's viewport
export function IntersectionOffset({
  onMeasure,
  sectionCount
}: IntersectionOffsetProps) {
  const elementRef = useRef<HTMLDivElement[]>([])

  useEffect(() => {
    // useEffect occurs after the refs are resolved (but prior to updating the real DOM) so current should contain all of our sections
    if (elementRef.current === null) return
    const elements = elementRef.current

    // the callback will receive an IntersectionObserverEntry for each of our observed sections.
    // This lets us do the math on all sections at once so we don't have to save or coordinate their state anywhere
    const intersectionObserver = new IntersectionObserver(event => {
      onMeasure(getOffset(event))
    })

    // observe sections and clean up on unmount
    elements.forEach(element => intersectionObserver.observe(element))
    return () => {
      elements.forEach(element => intersectionObserver.unobserve(element))
    }
  }, [elementRef, onMeasure])

  // full height div with a number of equally sized vertical sections used to infer offset
  return (
    <div
      style={{
        position: 'fixed',
        inset: 0,
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {range(sectionCount).map(index => (
        <div
          ref={r => {
            if (!r) return
            elementRef.current[index] = r
          }}
          style={{ flexGrow: 1 }}
        />
      ))}
    </div>
  )
}
