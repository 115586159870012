import { Box, InputMessage } from '@te-whatu-ora/anatomic'
import { InputLabel } from 'components/fhirEngine/inputLabel/InputLabel'
import { GroupBase, MultiValue } from 'react-select'
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate'
import {
  Coding,
  SearchableMultiSelectProps,
  SearchResponse
} from '@healthnz-ult/fhir-engine-renderer/types'
import React from 'react'
import {
  ClearIndicator,
  Control,
  DropdownIndicator,
  MultiValue as MultiValueComponent
} from './components'

export interface Option {
  label: string
  value: Coding
}

export function toOption(value: Coding): Option {
  return {
    label: value.display ?? value.code ?? '',
    value
  }
}

export default function MultiSelect(props: SearchableMultiSelectProps) {
  const {
    error,
    helperText,
    inputId,
    onBlur,
    onChange,
    onFocus,
    getOptions,
    value
  } = props

  const valueAsOptions = value?.map(toOption) ?? []

  function handleChange(newValue: MultiValue<Option>) {
    onChange(newValue.map(v => v.value))
  }

  const handleSearch: LoadOptions<
    Option,
    GroupBase<Option>,
    (() => Promise<SearchResponse>) | undefined
  > = async (searchTerm, loadedOptions, additional) => {
    // start a new search or get next page in existing search
    const searchResult = additional ? additional() : getOptions(searchTerm)

    return searchResult.then(result => ({
      options: result.options.map(toOption),
      hasMore: result.nextPage !== undefined,
      additional: result.nextPage
    }))
  }

  return (
    <Box>
      <InputLabel {...props} />
      <AsyncPaginate
        components={{
          ClearIndicator,
          Control,
          DropdownIndicator,
          MultiValue: MultiValueComponent
        }}
        inputId={inputId}
        isClearable
        isMulti
        loadOptions={handleSearch}
        onBlur={onBlur}
        onChange={handleChange}
        onFocus={onFocus}
        required
        value={valueAsOptions}
      />
      <InputMessage errorMessage={error} helperText={helperText} />
    </Box>
  )
}
