/* eslint-disable max-classes-per-file */

export class CustomError extends Error {
  canRecover: boolean

  constructor(name: string, message?: string, canRecover: boolean = true) {
    super(message)
    this.name = name
    this.canRecover = canRecover
  }
}

export class GenericError extends CustomError {
  constructor(errorMessage?: string, canRecover?: boolean) {
    super(
      'Something went wrong',
      errorMessage ??
        `An unexpected error has occurred and we're unable to display this questionnaire.`,
      canRecover
    )
  }
}

export class GenericNetworkError extends CustomError {
  constructor(canRecover?: boolean) {
    super('Network error', 'Please refresh the page.', canRecover)
  }
}

export class ForbiddenError extends CustomError {
  constructor(errorMessage?: string, canRecover?: boolean) {
    super(
      'Access denied',
      errorMessage ??
        `An unexpected error has occurred and we're unable to authorise you.`,
      canRecover
    )
  }
}

export class NotFoundError extends CustomError {
  constructor(errorMessage?: string, canRecover: boolean = false) {
    super(
      'Questionnaire not found',
      errorMessage ?? 'We were unable to find your questionnaire.',
      canRecover
    )
  }
}

export class ExpiredAuthError extends ForbiddenError {
  constructor() {
    super('Your access to this questionnaire has expired.', false)
  }
}

export function handleNetworkResponse(res: Response) {
  if (res.status === 403) {
    throw new ForbiddenError()
  }
  if (res.status === 404) throw new NotFoundError()
  if (res.status < 200 || res.status >= 300) throw new GenericNetworkError()

  return res
}
