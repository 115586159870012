import { PropsWithChildren, useEffect, useMemo } from 'react'

function checkChildrenForAsterisk(element: ChildNode) {
  // Check if the current element is a text node and contains ONLY an asterisk
  if (element.nodeType === Node.TEXT_NODE && element.textContent === '*') {
    // Remove the asterisk from the text content
    // eslint-disable-next-line no-param-reassign
    element.textContent = element.textContent.replace(/\*/g, '')
  } else {
    // If not a text node, iterate over its children
    element.childNodes.forEach(checkChildrenForAsterisk)
  }
}

function removeRequiredAsterisk(selector: string) {
  const elements = document.querySelectorAll(`.${selector}`)
  elements.forEach(checkChildrenForAsterisk)
}

function sanitizeInputId(inputId: string) {
  // The selector contains the input it which can contain "."
  return inputId.replace(/\./g, '-')
}

interface RequiredInputWrapperProps extends PropsWithChildren {
  inputId: string
  labelComponent?: React.ReactNode
  required?: boolean
}

// Removes the '*' added by Anatomic when the item has a labelComponent and is required.
// Allows for the user to define any structure and put the '*' where they see fit.
export function RequiredInputWrapper({
  children,
  inputId,
  labelComponent,
  required
}: RequiredInputWrapperProps) {
  const id = useMemo(() => {
    return sanitizeInputId(inputId)
  }, [inputId])

  useEffect(() => {
    if (labelComponent && required)
      removeRequiredAsterisk(`input-anatomic-${id}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div className={`input-anatomic-${id}`}>{children}</div>
}
