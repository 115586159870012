import { ToggleButton, InputMessage } from '@te-whatu-ora/anatomic'
import type { ToggleButtonInputProps } from '@healthnz-ult/fhir-engine-renderer/types'
import { InputLabel } from 'components/fhirEngine/inputLabel/InputLabel'
import { InputFieldset } from '../inputFieldset'

interface EnrichedToggleButtonInputProps extends ToggleButtonInputProps {
  handleChange(val: string): void
}

function BooleanInput({
  disabled,
  handleChange,
  options,
  value
}: EnrichedToggleButtonInputProps) {
  return (
    <ToggleButton
      disabled={disabled}
      defaultValue={value ? `index-${options[value].value}` : undefined}
      labelLeft={options[0].label}
      valueLeft={`index-${options[0].value}`}
      labelRight={options[1].label}
      valueRight={`index-${options[1].value}`}
      onChange={handleChange}
    />
  )
}

function ToggleInput(props: EnrichedToggleButtonInputProps) {
  const { options } = props

  switch (options.length) {
    case 0:
      return <p>No options provided</p>
    case 1:
      return <p>Not enough options provided</p>
    case 2:
      return <BooleanInput {...props} />
    default:
      return <p>Too many options provided</p>
  }
}

export default function ToggleButtonInput(props: ToggleButtonInputProps) {
  const { helperText, inputId, onChange, error } = props

  function handleChange(newValue: string) {
    if (!newValue.startsWith('index-')) return

    const parsedValue = Number.parseInt(newValue.slice(6), 10)

    onChange(parsedValue)
  }

  return (
    <InputFieldset {...props}>
      <ToggleInput {...props} handleChange={handleChange} />

      <InputMessage
        errorMessage={error}
        errorMessageProps={{ id: inputId }}
        helperText={helperText}
      />
    </InputFieldset>
  )
}
